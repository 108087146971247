import images from 'assets/images';

// SMARanking01Component - 부문별 순위 확인
export const SMA_AWARD_RANKING = [
	{
		id: 1,
		nomineeTitle: '월드 트렌드 아티스트'
	},
	{
		id: 2,
		nomineeTitle: '팬 초이스 오브 더 이어'
	}
];
