import { Box, styled } from '@mui/material';

const StyledResponsiveImageWrapper = styled(Box)(({ theme }) => ({
	position: 'relative',
	width: '100%',
	height: 0
}));

const StyledResponsiveImage = styled('img')(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%'
}));

const ResponsiveImage = ({ src, alt, imgProps, wrapperProps }) => {
	return (
		<StyledResponsiveImageWrapper {...wrapperProps}>
			<StyledResponsiveImage src={src} alt={alt} {...imgProps} />
		</StyledResponsiveImageWrapper>
	);
};

export default ResponsiveImage;
