import { Box, Button, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Main = styled('div')({
	height: '100vh',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center'
});

const NavigateButton = styled(Button)({
	borderRadius: '24px',
	fontSize: '1.5rem'
});

const NotFoundPage = () => {
	const navigate = useNavigate();

	const handleMoveBack = () => {
		navigate(-1);
	};

	return (
		<Main>
			<Typography variant={'h10'} color={'#101041'}>
				원하시는 페이지를 찾을 수 없습니다.
			</Typography>
			<Typography
				variant={'caption1'}
				fontWeight={400}
				lineHeight={1.4}
				textAlign={'center'}
				mt={2}
			>
				찾으려는 페이지 주소가 잘못 입력되었거나,
				<br />
				주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다.
			</Typography>
			<Box mt={3}>
				<NavigateButton
					variant={'outlined'}
					size={'large'}
					onClick={handleMoveBack}
				>
					이전 화면으로 돌아가기
				</NavigateButton>
			</Box>
		</Main>
	);
};

export default NotFoundPage;
