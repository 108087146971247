import { Box, Container, styled, useMediaQuery, useTheme } from '@mui/material';

import images from 'assets/images';

const StyledHeaderContainer = styled(Container)(({ theme }) => ({
	height: '74px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}));

const StyledMobileHeaderContainer = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	zIndex: 999,
	height: '60px',
	padding: '15px 20px',
	background: 'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%)'
}));

const Header = () => {
	const theme = useTheme();
	const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

	if (matchUpSm) {
		return (
			<Box component={'header'} className={'border-b-1 border-grey3'}>
				<StyledHeaderContainer maxWidth={'lg'}>
					<img
						src={images.DreamConcertLogo29th}
						alt={'드림콘서트_로고'}
						width={'160px'}
						height={'30px'}
					/>
				</StyledHeaderContainer>
			</Box>
		);
	}

	return (
		<StyledMobileHeaderContainer component={'header'}>
			<img
				src={images.DreamConcertLogo29thLight}
				alt={'드림콘서트_로고'}
				width={'124px'}
				height={'24px'}
			/>
		</StyledMobileHeaderContainer>
	);
};

export default Header;
