import { useEffect, useRef, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import {
	Artist,
	Banner,
	Footer,
	Header,
	MainContent,
	MainBottom
} from 'views/dreamConcert/dreamRookie/components';

import { ContentsData } from 'views/dreamConcert/dreamRookie/constants';

import { getRookie } from 'api/rookie';

const DreamRookieRootPage = () => {
	const theme = useTheme();
	const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

	const scrollRef = useRef(null);
	const [scrollY, setScrollY] = useState(0);

	const contentsRef = useRef(null);
	const [selectedContents, setSelectedContents] = useState(
		ContentsData[0].value
	);

	const handleScroll = () => {
		setScrollY(window.scrollY);
	};

	const fetchGetRookie = async () => {
		try {
			const data = await getRookie();
		} catch (error) {}
	};

	useEffect(() => {
		fetchGetRookie();
	}, []);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const handleClickContents = (value) => {
		if (contentsRef.current) {
			contentsRef.current.style.scrollMargin = matchUpSm ? '30px' : '50px';
			contentsRef.current.scrollIntoView({
				behavior: 'smooth'
			});
			setSelectedContents(value);
		}
	};

	if (matchUpSm) {
		return (
			<div ref={scrollRef}>
				<Header />
				<Box component={'main'}>
					{/*<SubBanner />*/}
					<Banner scrollY={scrollY} />
					<MainContent
						selectedContents={selectedContents}
						handleClickContents={handleClickContents}
					/>
					<Artist
						contentsRef={contentsRef}
						selectedContents={selectedContents}
					/>
					<MainBottom />
				</Box>
				<Footer />
			</div>
		);
	}

	return (
		<div>
			<Header />
			<Box component={'main'}>
				<Banner />
				{/*<SubBanner />*/}
				<MainContent
					selectedContents={selectedContents}
					handleClickContents={handleClickContents}
				/>
				<Artist contentsRef={contentsRef} selectedContents={selectedContents} />
				<MainBottom />
			</Box>
			<Footer />
		</div>
	);
};

export default DreamRookieRootPage;
