import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
	SMARealTrophy,
	Banner,
	SMA01Component,
	SMA02Component,
	SMA03Component,
	SMA04Component,
	SMA05Component,
	SMA06Component,
	SMAPolicyModal
} from 'views/sma/smaMonth/root/components';

import { Header, Footer } from 'views/sma/smaMonth/components';

import { SMARanking01Component } from 'views/sma/smaMonth/ranking/components';

const SmaMonthRootPage = () => {
	const { pathname } = useLocation();

	const [isOpenModal, setIsOpenModal] = useState(false);

	const handleOpenPolicyModal = () => {
		setIsOpenModal(!isOpenModal);
	};

	return (
		<div>
			<Header />
			{pathname === '/sma/month/about' ? (
				<>
					<div>
						<SMARealTrophy />
						<Banner />
						<div id={'smaMonth01'}>
							<SMA01Component />
						</div>
					</div>
					<div id={'smaMonth02'}>
						<SMA02Component />
					</div>
					<div id={'smaMonth03'}>
						<SMA03Component />
					</div>
					<div id={'smaMonth04'}>
						<SMA04Component />
						<SMA05Component handleOpenPolicyModal={handleOpenPolicyModal} />
						<SMAPolicyModal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
					</div>
					<SMA06Component />
				</>
			) : (
				<>
					<div>
						<SMARealTrophy />
						<Banner />
					</div>
					<div id={'smaMonth05'}>
						<SMARanking01Component />
					</div>
					<SMA06Component />
				</>
			)}
			<Footer />
		</div>
	);
};

export default SmaMonthRootPage;
