import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, styled, useMediaQuery, useTheme } from '@mui/material';

import images from 'assets/images';

const StyledHeader = styled(Box)(({ matchUpSm }) => ({
	height: matchUpSm ? '760px' : '670px',
	backgroundImage: `url(${images.FancastAppPreview})`,
	backgroundSize: 'cover',
	backgroundPosition: '15%'
}));

const StyledContainer = styled(Container)(({ matchUpSm }) => ({}));

const Header = () => {
	const theme = useTheme();
	const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

	const { pathname } = useLocation();
	const navigate = useNavigate();

	const handleMoveRoot = () => {
		navigate('/');
	};

	if (pathname === '/') {
		return (
			<StyledHeader component={'header'} matchUpSm={matchUpSm}>
				<StyledContainer maxWidth={'lg'}>
					<Box py={'25px'} px={matchUpSm ? '25px' : '20px'}>
						<img
							src={images.FancastLogoHorizontal}
							alt={'Fancast'}
							width={'173px'}
							style={{ objectFit: 'contain', cursor: 'pointer' }}
							onClick={handleMoveRoot}
						/>
					</Box>
				</StyledContainer>
			</StyledHeader>
		);
	}

	return (
		<Box bgcolor={theme.palette.legacy.darkNavy}>
			<StyledContainer maxWidth={'lg'}>
				<Box py={'25px'} px={matchUpSm ? '25px' : '20px'}>
					<img
						src={images.FancastLogoHorizontal}
						alt={'Fancast'}
						width={'173px'}
						style={{ objectFit: 'contain', cursor: 'pointer' }}
						onClick={handleMoveRoot}
					/>
				</Box>
			</StyledContainer>
		</Box>
	);
};

export default Header;
