const typography = () => {
	return {
		fontFamily: "'Pretendard'",
		h1: {
			fontSize: '6rem',
			fontWeight: 700
		},
		h2: {
			fontSize: '4.6rem',
			fontWeight: 700
		},
		h3: {
			fontSize: '4.4rem',
			fontWeight: 700
		},
		h4: {
			fontSize: '4.2rem',
			fontWeight: 700
		},
		h5: {
			fontSize: '4rem',
			fontWeight: 700
		},
		h6: {
			fontSize: '3.8rem',
			fontWeight: 700
		},
		h7: {
			display: 'block',
			fontSize: '3.6rem',
			fontWeight: 700
		},
		h8: {
			display: 'block',
			fontSize: '3.4rem',
			fontWeight: 700
		},
		h9: {
			display: 'block',
			fontSize: '3.2rem',
			fontWeight: 700
		},
		h10: {
			display: 'block',
			fontSize: '3rem',
			fontWeight: 700
		},
		h11: {
			display: 'block',
			fontSize: '2.8rem',
			fontWeight: 700
		},
		h12: {
			display: 'block',
			fontSize: '2.6rem',
			fontWeight: 700
		},
		h13: {
			display: 'block',
			fontSize: '2.4rem',
			fontWeight: 700
		},
		h14: {
			display: 'block',
			fontSize: '2.2rem',
			fontWeight: 700
		},
		caption1: {
			fontSize: '2rem',
			fontWeight: 400
		},
		caption2: {
			fontSize: '1.8rem',
			fontWeight: 400
		},
		caption3: {
			fontSize: '1.6rem',
			fontWeight: 400
		},
		caption4: {
			fontSize: '1.5rem',
			fontWeight: 400
		},
		caption5: {
			fontSize: '1.4rem',
			fontWeight: 400
		},
		caption6: {
			fontSize: '1.3rem',
			fontWeight: 400
		},
		caption7: {
			fontSize: '1.2rem',
			fontWeight: 400
		},
		caption8: {
			fontSize: '1.1rem',
			fontWeight: 400
		}
	};
};

export default typography;
