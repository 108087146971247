import {
	Box,
	ButtonBase,
	Container,
	Grid,
	Link,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const StyledTermsButton = styled(ButtonBase)(({ theme }) => ({}));

const Footer = () => {
	const theme = useTheme();
	const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

	const navigate = useNavigate();

	const handleMoveService = () => {
		navigate('/service');
	};

	const handleMovePrivacy = () => {
		navigate('/privacy');
	};

	return (
		<Box component={'footer'} bgcolor={theme.palette.legacy.darkNavy} pt={10} pb={8}>
			<Container maxWidth={'lg'}>
				<Grid container mb={2}>
					<Box px={matchUpSm ? 0 : '20px'}>
						<ButtonBase disableRipple onClick={handleMoveService}>
							<Typography
								variant={'caption2'}
								color={theme.palette.white[1]}
								fontWeight={600}
							>
								이용약관
							</Typography>
						</ButtonBase>
						<ButtonBase sx={{ ml: 2 }} onClick={handleMovePrivacy}>
							<Typography
								variant={'caption2'}
								color={theme.palette.white[1]}
								fontWeight={600}
							>
								개인정보처리방침
							</Typography>
						</ButtonBase>
						<Grid mt={2}>
							<Typography
								variant={'caption4'}
								color={theme.palette.white[1]}
								lineHeight={1.8}
							>
								사업자등록번호 : 759-88-02205 ㅣ 통신판매업신고번호 :
								제2022-서울영등포-2485호 ㅣ 주식회사 콘텐츠마당 ㅣ 대표이사 조한종
								<br />
								서울시 영등포구 영신로 166, 908호(영등포 반도아이비밸리) ㅣ © 2022
								CONTENTSMADANG, INC. ALL Rights Reserved.
							</Typography>
						</Grid>
					</Box>
				</Grid>
			</Container>
		</Box>
	);
};

export default Footer;
