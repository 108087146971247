import {
	Box,
	Container,
	Grid,
	Link,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import * as ReactScroll from 'react-scroll';
import { useNavigate } from 'react-router-dom';

import images from 'assets/images';

import { MENUS } from 'views/sma/smaMonth/constants';

const StyledMenuLink = styled(Link)(({ theme, matchUpMd }) => ({
	color: theme.palette.grey[1],
	fontSize: '1.4rem',
	textDecorationColor: theme.palette.grey[1],
	cursor: 'pointer',
	...(matchUpMd && {
		marginLeft: '40px'
	}),
	...(!matchUpMd && {
		marginTop: '24px'
	})
}));

const Footer = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	const scroller = ReactScroll.scroller;

	const handleClickMenu = async (menu) => {
		await navigate(menu.url);
		await scroller.scrollTo(menu.id, {
			spy: true,
			smooth: true,
			offset: -40
		});
	};

	return (
		<Box
			sx={{
				pt: matchUpMd ? '60px' : '40px',
				pb: matchUpMd ? '72px' : '60px',
				px: matchUpMd ? 0 : '20px',
				backgroundColor: theme.palette.grey[4]
			}}
		>
			<Container maxWidth={1200}>
				<Grid container>
					{matchUpMd ? (
						<Grid item md={6}>
							<Typography
								variant={'caption3'}
								fontWeight={600}
								color={theme.palette.black[1]}
							>
								서울가요대상 이달의 아티스트
							</Typography>
						</Grid>
					) : (
						<Grid container alignItems={'center'} justifyContent={'space-between'}>
							<Grid item md={5.5}>
								<Typography
									variant={'caption3'}
									fontWeight={700}
									color={theme.palette.black[1]}
								>
									서울가요대상
									<br />
									이달의 아티스트
								</Typography>
							</Grid>
							<Grid item md={6.5}>
								<img
									src={images.SmaLogoHorizontal}
									alt={'sma_logo_horizontal'}
									width={182}
									height={22}
								/>
							</Grid>
						</Grid>
					)}
					<Grid
						item
						xs={12}
						md={6}
						display={'flex'}
						justifyContent={matchUpMd ? 'flex-end' : 'space-between'}
					>
						{MENUS.map((menu, index) => (
							<StyledMenuLink
								key={`menu-item-${index}`}
								matchUpMd={matchUpMd}
								onClick={() => handleClickMenu(menu)}
							>
								{menu.text}
							</StyledMenuLink>
						))}
					</Grid>

					{matchUpMd && (
						<Grid item md={6} pt={'8px'}>
							<img
								src={images.SmaLogoHorizontal}
								alt={'sma_logo_horizontal'}
								width={230}
								height={28}
							/>
						</Grid>
					)}

					<Grid item md={12} pt={'40px'}>
						<Typography
							variant={'caption2'}
							fontWeight={600}
							color={theme.palette.black[1]}
						>
							주식회사 콘텐츠마당
						</Typography>
					</Grid>

					<Grid item md={12} pt={'16px'}>
						<Typography
							variant={'caption5'}
							fontWeight={400}
							color={theme.palette.grey[1]}
						>
							사업자등록번호 : 759-88-02205 | 대표이사 : 조한종 | 통신판매업신고번호 :
							제2022-서울영등포-2485호
							<br />
							07251, 서울시 영등포구 영신로 166, 908호(영등포 반도아이비밸리)
						</Typography>
					</Grid>

					<Grid item md={12} pt={'9px'}>
						<Typography
							variant={'caption7'}
							fontWeight={400}
							color={theme.palette.grey[2]}
						>
							©2023 CONTENTSMADANG, INC. ALL Rights Reserved.
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Footer;
