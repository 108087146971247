import { Fragment } from 'react';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';

import { Header, Footer } from 'views/fancast/legacy/components';

const FancastRootPage = () => {
	const theme = useTheme();
	const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

	return (
		<Fragment>
			<Header />
			<Container
				component={'main'}
				maxWidth={'lg'}
				className={'Fancast-Legacy'}
				sx={{ pt: 5, pb: 10 }}
			>
				<Box px={matchUpSm ? 0 : '20px'}>
					admixer.co.kr,1407,RESELLER
					<br />
					admixer.co.kr,1396,RESELLER
					<br />
					admixer.co.kr,1164,RESELLER
					<br />
					tpmn.io, 277, RESELLER
					<br />
					tpmn.co.kr, 277, RESELLER
					<br />
					mman.kr, 32428, RESELLER
					<br />
					pangleglobal.com, 67894, RESELLER
					<br />
					pubmatic.com, 161490, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 162161, RESELLER, 5d62403b186f2ace
					<br />
					yeahmobi.com,107432, RESELLER
					<br />
					algorix.co, 54845, RESELLER, 5b394c12fea27a1d
					<br />
					ucfunnel.com, par-6272B92A28D87A77CE3E44D466486424, RESELLER
					<br />
					aralego.com, par-6272B92A28D87A77CE3E44D466486424, RESELLER
					<br />
					adiiix.com, par-6272B92A28D87A77CE3E44D466486424, RESELLER
					<br />
					tpmn.io, 283, RESELLER
					<br />
					tpmn.co.kr, 283, RESELLER
					<br />
					smartyads.com,100118, RESELLER, fd2bde0ff2e62c5d
					<br />
					adform.com, 2795, RESELLER
					<br />
					loopme.com, 11426, RESELLER, 6c8d5f95897a5a3b
					<br />
					video.unrulymedia.com, 3563866356, RESELLER
					<br />
					consumable.com, 2001482, RESELLER, aefcd3d2f45b5070
					<br />
					acexchange.co.kr, 1854269911, RESELLER
					<br />
					pubmatic.com, 163120, RESELLER, 5d62403b186f2ace
					<br />
					ucfunnel.com, par-AA7B236BA32DD484C838E249362437B8, RESELLER
					<br />
					aralego.com, par-AA7B236BA32DD484C838E249362437B8, RESELLER
					<br />
					adiiix.com, par-AA7B236BA32DD484C838E249362437B8, RESELLER
					<br />
					loopme.com, 11544, RESELLER, 6c8d5f95897a5a3b
					<br />
					ucfunnel.com, par-AA7BB9E49434DE3B1B9D9A3373B69DE, DIRECT
					<br />
					aralego.com, par-AA7BB9E49434DE3B1B9D9A3373B69DE, DIRECT
					<br />
					adiiix.com, par-AA7BB9E49434DE3B1B9D9A3373B69DE, DIRECT
					<br />
					inmobi.com, ab915bcef5b24940bf745f1a8f427bec, RESELLER, 83e75a7ae333ca9d
					<br />
					outbrain.com, 00bba279fec6daa01a0cb6fdccb023f0d5, RESELLER
					<br />
					pubmatic.com, 159035, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 158738, RESELLER, 5d62403b186f2ace
					<br />
					improvedigital.com, 1532, RESELLER
					<br />
					verve.com, 5897, RESELLER, 0c8f5958fc2d6270
					<br />
					rubiconproject.com, 12266, RESELLER, 0bfd66d529a55807
					<br />
					spotx.tv, 82068, RESELLER, 7842df1d2fe2db34
					<br />
					rubiconproject.com, 16928, RESELLER, 0bfd66d529a55807
					<br />
					rubiconproject.com, 11726, RESELLER, 0bfd66d529a55807
					<br />
					rubiconproject.com, 20208, RESELLER, 0bfd66d529a55807
					<br />
					blis.com, 33, RESELLER, 61453ae19a4b73f4
					<br />
					spotxchange.com, 82068, RESELLER, 7842df1d2fe2db34
					<br />
					rhythmone.com, 3704396951, RESELLER, a670c89d4a324e47
					<br />
					loopme.com, 9724, RESELLER, 6c8d5f95897a5a3b
					<br />
					pubmatic.com, 156931, RESELLER, 5d62403b186f2ace
					<br />
					appnexus.com, 7597, RESELLER, f5ab79cb980f11d1
					<br />
					admixer.net, 64636a4f-c002-4d09-a7ec-960530b32b94, RESELLER
					<br />
					rhythmone.com, 188404962, RESELLER, a670c89d4a324e47
					<br />
					sonobi.com, cc3858f35e, RESELLER, d1a215d9eb5aee9e
					<br />
					lemmatechnologies.com, 89, RESELLER, 7829010c5bebd1fb
					<br />
					axonix.com, 57716, RESELLER
					<br />
					algorix.co, 54190, RESELLER
					<br />
					rubiconproject.com, 19744, RESELLER, 0bfd66d529a55807
					<br />
					pubnative.net, 1006951, RESELLER, d641df8625486a7b
					<br />
					appnexus.com, 2764, RESELLER
					<br />
					smartadserver.com, 3232, RESELLER
					<br />
					openx.com, 540298543, RESELLER, 6a698e2ec38604c6
					<br />
					pubmatic.com, 157097, RESELLER, 5d62403b186f2ace
					<br />
					velismedia.com, 1111, RESELLER
					<br />
					telaria.com, r3222-wec7r, RESELLER, 1a4e959a1b50034a
					<br />
					pubmatic.com, 157153, RESELLER, 5d62403b186f2ace
					<br />
					xad.com, 1190, RESELLER, 81cbf0a75a5e0e9a
					<br />
					indexexchange.com, 184738, RESELLER
					<br />
					epom.com, 8906083d-8c91-4a0e-bab3-3aad42f718c, RESELLER, a05085f3142a1ca8
					<br />
					pubmatic.com, 158772, RESELLER, 5d62403b186f2ace
					<br />
					admixer.net, 51dc18e4-86e3-42de-9a82-341b2cb39c2e, DIRECT
					<br />
					onetag.com, 59d216e971852f2, RESELLER
					<br />
					meitu.com, 251, RESELLER
					<br />
					pubmatic.com, 157654, RESELLER, 5d62403b186f2ace
					<br />
					adcolony.com, f858ba060bce51ad, RESELLER, 1ad675c9de6b5176
					<br />
					openx.com, 540899687, RESELLER, 6a698e2ec38604c6
					<br />
					admixer.co.kr, 1370, DIRECT
					<br />
					admixer.net, 0072fb58-999b-445e-9a9b-3fc2a7194277, RESELLER
					<br />
					Pubnative.net, 1006231, RESELLER, d641df8625486a7b
					<br />
					Pubmatic.com, 155975, RESELLER, 5d62403b186f2ace
					<br />
					Openx.com, 537152826, RESELLER, 6a698e2ec38604c6
					<br />
					Openx.com, 539472296, RESELLER, 6a698e2ec38604c6
					<br />
					Appnexus.com, 8178, RESELLER, f5ab79cb980f11d1
					<br />
					Contextweb.com, 561884, RESELLER, 89ff185a4c4e857c
					<br />
					Adcolony.com, 1efc6603710003ea, RESELLER, 1ad675c9de6b5176
					<br />
					Rhythmone.com, 4173858586, RESELLER, a670c89d4a324e47
					<br />
					Improvedigital.com, 1366, RESELLER
					<br />
					EMXDGT.com, 1324, RESELLER, 1e1d41537f7cad7f
					<br />
					Engagebdr.com, 10252, RESELLER #banner #video
					<br />
					bidmachine.io, 55, RESELLER
					<br />
					bidmachine.io, 59, RESELLER
					<br />
					rubiconproject.com, 14334, DIRECT, 0bfd66d529a55807
					<br />
					rubiconproject.com, 14334, RESELLER, 0bfd66d529a55807
					<br />
					smartyads.com, 100036, DIRECT, fd2bde0ff2e62c5d
					<br />
					improvedigital.com, 1797, RESELLER
					<br />
					pubmatic.com, 158651, RESELLER, 5d62403b186f2ace
					<br />
					smartadserver.com, 1894, RESELLER
					<br />
					gumgum.com, 13706, DIRECT, ffdef49475d318a9
					<br />
					thebrave.io, 490457, RESELLER, c25b2154543746acpeak226.com, 12400, RESELLER
					<br />
					meitu.com, 285, RESELLER
					<br />
					bizzclick.com, 30, RESELLER, 7e936b1feafdaa61
					<br />
					pubnative.net, 1003459, RESELLER, d641df8625486a7b
					<br />
					pubmatic.com, 160145, RESELLER, 5d62403b186f2ace
					<br />
					rubiconproject.com, 17328, RESELLER, 0bfd66d529a55807
					<br />
					bidmachine.io, 138, RESELLER
					<br />
					inmobi.com, f3924290136e4129a5c082ff982c3a58, RESELLER, 83e75a7ae333ca9d
					<br />
					Verve.com, 15503, RESELLER, 0c8f5958fc2d6270 #Verve
					<br />
					Rubiconproject.com, 15278, RESELLER, 0bfd66d529a55807 #Verve
					<br />
					Inmobi.com, c1e6d3502da64ebaa3ad0e4a4be15f11, RESELLER, 83e75a7ae333ca9d #Verve
					<br />
					Pubmatic.com, 156517, RESELLER, 5d62403b186f2ace #Verve
					<br />
					Sabio.us, 100032, RESELLER, 96ed93aaa9795702 #Verve
					<br />
					openx.com, 540543195, RESELLER, 6a698e2ec38604c6 #Verve
					<br />
					Contextweb.com, 561849, RESELLER, 89ff185a4c4e857c #Verve
					<br />
					indexexchange.com, 192829, RESELLER, 50b1c356f2c5c8fc
					<br />
					indexexchange.com, 191332, RESELLER, 50b1c356f2c5c8fc
					<br />
					admixer.net, 8e380da6-31ba-488c-939c-290c48d577e4, RESELLER
					<br />
					onetag.com, 59aa7be4921bac8, RESELLER
					<br />
					advertising.com, 28246, RESELLER
					<br />
					sonobi.com, eaec54c63f, RESELLER, d1a215d9eb5aee9e
					<br />
					improvedigital.com, 1210, RESELLER
					<br />
					loopme.com, 11322, RESELLER, 6c8d5f95897a5a3b
					<br />
					rubiconproject.com, 20744, RESELLER, 0bfd66d529a55807
					<br />
					mman.kr,32002,DIRECT
					<br />
					mman.kr,32002,RESELLER
					<br />
					mman.kr,30915,DIRECT
					<br />
					mman.kr,30915,RESELLER
					<br />
					bidence.com, bb8088e44b63ec0ee086d3f1933d001e, RESELLER
					<br />
					bidence.com, 483780f4c7c5d77ab30eeceaffc0e506, RESELLER
					<br />
					acexchange.co.kr, 1081983882, RESELLER
					<br />
					adcolony.com, f858ba060bce51ad, RESELLER
					<br />
					bidence.com, 1c671a01123b03e998a4d4b1ea9e7141, RESELLER
					<br />
					bizzclick.com, 30, RESELLER
					<br />
					Contextweb.com, 561884, RESELLER
					<br />
					dable.io, pub-0mg2xex3v1e587w9, RESELLER
					<br />
					dable.io, pub-zk310rz3jmpm5xlw, RESELLER
					<br />
					engagebdr.com, 10252, RESELLER
					<br />
					indexexchange.com, 192829, RESELLER, 50b1c356f2c5c8fc #Verve
					<br />
					lkqd.net, 647, RESELLER, 59c49fa9598a0117
					<br />
					lkqd.net, 654, RESELLER, 59c49fa9598a0117
					<br />
					Pubnative.net, 1007466, RESELLER, d641df8625486a7b
					<br />
					bizzclick.com, 246, DIRECT, 7e936b1feafdaa61
					<br />
					onetag.com, 5d100f439ce5dcc, DIRECT
					<br />
					betweendigital.com, 43683, DIRECT
					<br />
					sovrn.com, 297837, DIRECT, fafdf38b16bf6b2b
					<br />
					lijit.com, 297837, DIRECT, fafdf38b16bf6b2b
					<br />
					acexchange.co.kr, 1017355401, Reseller
					<br />
					adcolony.com, 1efc6603710003ea, Reseller
					<br />
					admixer.co.kr, 1370, Reseller
					<br />
					appnexus.com, 8178, Reseller
					<br />
					Contextweb.com, 561849, Reseller
					<br />
					Inmobi.com, c1e6d3502da64ebaa3ad0e4a4be15f11, Reseller
					<br />
					inmobi.com, f3924290136e4129a5c082ff982c3a58, Reseller
					<br />
					mman.kr, 30769, Reseller
					<br />
					mman.kr, 30915, Reseller
					<br />
					mman.kr, 32002, Reseller
					<br />
					Openx.com, 539472296, Reseller
					<br />
					openx.com, 540543195, Reseller
					<br />
					openx.com, 540899687, Reseller
					<br />
					peak226.com, 12400, Reseller
					<br />
					Pubmatic.com, 155975, Reseller
					<br />
					Pubmatic.com, 156517, Reseller
					<br />
					pubmatic.com, 157654, Reseller
					<br />
					pubmatic.com, 158651, Reseller
					<br />
					Pubnative.net, 1006231, Reseller
					<br />
					rhythmone.com, 4173858586, Reseller
					<br />
					rubiconproject.com, 14334, Reseller
					<br />
					Rubiconproject.com, 15278, Reseller
					<br />
					rubiconproject.com, 17328, Reseller
					<br />
					Sabio.us, 100032, Reseller
					<br />
					thebrave.io, 490457, Reseller
					<br />
					tpmn.co.kr, 1, Reseller
					<br />
					tpmn.co.kr, 27, Reseller
					<br />
					tpmn.io, 1, Reseller
					<br />
					tpmn.io, 27, Reseller
					<br />
					Pubnative.net, 1007249, Reseller, d641df8625486a7b
					<br />
					Verve.com, 15290, Reseller, 0c8f5958fc2d6270#Verve
					<br />
					Verve.com, 15503, Reseller, 0c8f5958fc2d6270
					<br />
					mman.kr,31742,DIRECT
					<br />
					mman.kr,31742,RESELLER
					<br />
					cauly.co.kr, 16216, DIRECT
					<br />
					admixer.co.kr,1140,DIRECT
					<br />
					admixer.co.kr,1140,RESELLER
					<br />
					admixer.co.kr,1370,DIRECT
					<br />
					admixer.net,0072fb58-999b-445e-9a9b-3fc2a7194277,RESELLER
					<br />
					bizzclick.com,30,RESELLER,7e936b1feafdaa61
					<br />
					genieesspv.jp,1081,DIRECT
					<br />
					Inmobi.com,c1e6d3502da64ebaa3ad0e4a4be15f11,RESELLER,83e75a7ae333ca9d#Verve
					<br />
					mman.kr,31852,DIRECT
					<br />
					mman.kr,31852,RESELLER
					<br />
					mman.kr,30388,DIRECT
					<br />
					mman.kr,30388,RESELLER
					<br />
					acexchange.co.kr,1545466697,RESELLER
					<br />
					pubnative.net,1006231,RESELLER,d641df8625486a7b
					<br />
					smaato.com,110004021,DIRECT,07bcf65f187117b4
					<br />
					smaato.com,1100004890,DIRECT,07bcf65f187117b4
					<br />
					smaato.com,1100040212,DIRECT,07bcf65f187117b4
					<br />
					smaato.com,1100041435,DIRECT,07bcf65f187117b4
					<br />
					smartyads.com,100036,DIRECT,fd2bde0ff2e62c5d
					<br />
					tnkfactory.com,2497,DIRECT
					<br />
					tpmn.co.kr,319,RESELLER
					<br />
					rubiconproject.com,14334,RESELLER,0bfd66d529a55807
					<br />
					Pubmatic.com,155975,RESELLER,5d62403b186f2ace
					<br />
					Openx.com,537152826,RESELLER,6a698e2ec38604c6
					<br />
					Openx.com,539472296,RESELLER,6a698e2ec38604c6
					<br />
					Appnexus.com,8178,RESELLER,f5ab79cb980f11d1
					<br />
					Contextweb.com,561884,RESELLER,89ff185a4c4e857c
					<br />
					Adcolony.com,1efc6603710003ea,RESELLER,1ad675c9de6b5176
					<br />
					Rhythmone.com,4173858586,RESELLER,a670c89d4a324e47
					<br />
					Improvedigital.com,1366,RESELLER
					<br />
					EMXDGT.com,1324,RESELLER,1e1d41537f7cad7f
					<br />
					Engagebdr.com,10252,RESELLER#banner#video
					<br />
					bidmachine.io,55,RESELLER
					<br />
					bidmachine.io,59,RESELLER
					<br />
					improvedigital.com,1797,RESELLER
					<br />
					pubmatic.com,158651,RESELLER,5d62403b186f2ace
					<br />
					smartadserver.com,1894,RESELLER
					<br />
					gumgum.com,13706,DIRECT,ffdef49475d318a9
					<br />
					thebrave.io,490457,RESELLER,c25b2154543746ac
					<br />
					peak226.com,12400,RESELLER
					<br />
					meitu.com,285,RESELLER
					<br />
					openx.com,540298543,RESELLER
					<br />
					openx.com,540543195,RESELLER
					<br />
					Contextweb.com,561849,RESELLER
					<br />
					onetag.com,59aa7be4921bac8,RESELLER
					<br />
					appnexus.com,8178,RESELLER
					<br />
					sonobi.com,eaec54c63f,RESELLER
					<br />
					ucfunnel.com,par-AA7BB9E49434DE3B1B9D9A3373B69DE,DIRECT
					<br />
					aralego.com,par-AA7BB9E49434DE3B1B9D9A3373B69DE,DIRECT
					<br />
					adiiix.com,par-AA7BB9E49434DE3B1B9D9A3373B69DE,DIRECT
					<br />
					pubmatic.com,160145,RESELLER,5d62403b186f2ace
					<br />
					Verve.com,15503,RESELLER,0c8f5958fc2d6270#Verve
					<br />
					Pubmatic.com,156517,RESELLER,5d62403b186f2ace#Verve
					<br />
					Sabio.us,100032,RESELLER,96ed93aaa9795702#Verve
					<br />
					openx.com,540543195,RESELLER,6a698e2ec38604c6#Verve
					<br />
					Contextweb.com,561849,RESELLER,89ff185a4c4e857c#Verve
					<br />
					indexexchange.com,192829,RESELLER,50b1c356f2c5c8fc
					<br />
					indexexchange.com,191332,RESELLER,50b1c356f2c5c8fc
					<br />
					advertising.com,28246,RESELLER
					<br />
					thebrave.io,490457,RESELLER,c25b2154543746acpeak226.com
					<br />
					engagebdr.com,10252,RESELLER
					<br />
					sonobi.com,eaec54c63f,RESELLER,d1a215d9eb5aee9e
					<br />
					improvedigital.com,1210,RESELLER
					<br />
					loopme.com,11322,RESELLER,6c8d5f95897a5a3b
					<br />
					openx.com,540298543,RESELLER,6a698e2ec38604c6
					<br />
					lkqd.net,647,RESELLER,59c49fa9598a0117
					<br />
					lkqd.net,654,RESELLER,59c49fa9598a0117
					<br />
					admixer.co.kr,1370,RESELLER
					<br />
					video.unrulymedia.com,3704396951,RESELLER
					<br />
					Verve.com,15290,RESELLER,0c8f5958fc2d6270#Verve
					<br />
					spotxchange.com,234183,RESELLER,7842df1d2fe2db34#Verve
					<br />
					spotx.tv,234183,RESELLER,7842df1d2fe2db34#Verve
					<br />
					gamoshi.io,267-b3609,RESELLER
					<br />
					appnexus.com,11744,RESELLER,f5ab79cb980f11d1
					<br />
					liftoff.io,7f6945815e6,RESELLER
					<br />
					mobfox.com,303-b3610,RESELLER
					<br />
					vyadd.com,3579,RESELLER
					<br />
					advenuemedia.co.uk,397,RESELLER
					<br />
					advenuemedia.co.uk,398,RESELLER
					<br />
					mars.media,1010380,RESELLER,8624339f102fb076
					<br />
					reforge.in,353,RESELLER
					<br />
					pubmatic.com,157256,RESELLER,5d62403b186f2ace
					<br />
					improvedigital.com,1202,RESELLER
					<br />
					axonix.com,59041,RESELLER
					<br />
					Se7en.es,212432,RESELLER,064bc410192443d8
					<br />
					onetag.com,59d216e971852f2,RESELLER
					<br />
					outbrain.com,00bba279fec6daa01a0cb6fdccb023f0d5,RESELLER
					<br />
					pubmatic.com,159035,RESELLER,5d62403b186f2ace
					<br />
					pubmatic.com,158738,RESELLER,5d62403b186f2ace
					<br />
					improvedigital.com,1532,RESELLER
					<br />
					verve.com,5897,RESELLER,0c8f5958fc2d6270
					<br />
					spotx.tv,82068,RESELLER,7842df1d2fe2db34
					<br />
					blis.com,33,RESELLER,61453ae19a4b73f4
					<br />
					spotxchange.com,82068,RESELLER,7842df1d2fe2db34
					<br />
					rhythmone.com,3704396951,RESELLER,a670c89d4a324e47
					<br />
					loopme.com,9724,RESELLER,6c8d5f95897a5a3b
					<br />
					pubmatic.com,156931,RESELLER,5d62403b186f2ace
					<br />
					appnexus.com,7597,RESELLER,f5ab79cb980f11d1
					<br />
					rhythmone.com,188404962,RESELLER,a670c89d4a324e47
					<br />
					sonobi.com,cc3858f35e,RESELLER,d1a215d9eb5aee9e
					<br />
					lemmatechnologies.com,89,RESELLER,7829010c5bebd1fb
					<br />
					axonix.com,57716,RESELLER
					<br />
					algorix.co,54190,RESELLER
					<br />
					appnexus.com,2764,RESELLER
					<br />
					smartadserver.com,3232,RESELLER
					<br />
					pubmatic.com,157097,RESELLER,5d62403b186f2ace
					<br />
					velismedia.com,1111,RESELLER
					<br />
					telaria.com,r3222-wec7r,RESELLER,1a4e959a1b50034a
					<br />
					pubmatic.com,157153,RESELLER,5d62403b186f2ace
					<br />
					xad.com,1190,RESELLER,81cbf0a75a5e0e9a
					<br />
					indexexchange.com,184738,RESELLER
					<br />
					epom.com,8906083d-8c91-4a0e-bab3-3aad42f718c,RESELLER,a05085f3142a1ca8
					<br />
					pubmatic.com,158772,RESELLER,5d62403b186f2ace
					<br />
					meitu.com,251,RESELLER
					<br />
					pubmatic.com,157654,RESELLER,5d62403b186f2ace
					<br />
					adcolony.com,f858ba060bce51ad,RESELLER,1ad675c9de6b5176
					<br />
					openx.com,540899687,RESELLER,6a698e2ec38604c6
					<br />
					bidence.com,bb8088e44b63ec0ee086d3f1933d001e,RESELLER
					<br />
					bidence.com,483780f4c7c5d77ab30eeceaffc0e506,RESELLER
					<br />
					adcolony.com,f858ba060bce51ad,RESELLER
					<br />
					bidence.com,1c671a01123b03e998a4d4b1ea9e7141,RESELLER
					<br />
					Contextweb.com,561884,RESELLER
					<br />
					dable.io,pub-0mg2xex3v1e587w9,RESELLER
					<br />
					dable.io,pub-zk310rz3jmpm5xlw,RESELLER
					<br />
					indexexchange.com,192829,RESELLER,50b1c356f2c5c8fc#Verve
					<br />
					onetag.com,5d100f439ce5dcc,DIRECT
					<br />
					betweendigital.com,43683,DIRECT
					<br />
					sovrn.com,297837,DIRECT,fafdf38b16bf6b2b
					<br />
					lijit.com,297837,DIRECT,fafdf38b16bf6b2b
					<br />
					adcolony.com,1efc6603710003ea,RESELLER
					<br />
					Openx.com,539472296,RESELLER
					<br />
					openx.com,540899687,RESELLER
					<br />
					Pubmatic.com,155975,RESELLER
					<br />
					Pubmatic.com,156517,RESELLER
					<br />
					pubmatic.com,157654,RESELLER
					<br />
					pubmatic.com,158651,RESELLER
					<br />
					rhythmone.com,4173858586,RESELLER
					<br />
					Sabio.us,100032,RESELLER
					<br />
					thebrave.io,490457,RESELLER
					<br />
					Verve.com,15503,RESELLER,0c8f5958fc2d6270
					<br />
					bizzclick.com,246,DIRECT,7e936b1feafdaa61
					<br />
					tpmn.io,392,RESELLER
					<br />
					mars.media, 1010380, RESELLER, 8624339f102fb076
					<br />
					quantumdex.io, EXU5919, RESELLER
					<br />
					ssp.e-volution.ai, AJxF6R108a9M6CaTvK, RESELLER
					<br />
					lunamedia.io, 3b57f8b19b67a806513dec9b47557783, RESELLER, 524ecb396915caaf
					<br />
					themediagrid.com, R28I9J, RESELLER, 35d5010d7789b49d
					<br />
					pubmatic.com, 160493, RESELLER, 5d62403b186f2ace
					<br />
					betweendigital.com, 43092, RESELLER
					<br />
					ssp.logan.ai, AJxF6R2a9M6CaTvK, RESELLER
					<br />
					admixer.co.kr,1538,RESELLER
					<br />
					ucfunnel.com, par-AA7BB9E49434DE3B1B9D9A3373B69DE, RESELLER
					<br />
					aralego.com, par-AA7BB9E49434DE3B1B9D9A3373B69DE, RESELLER
					<br />
					adiiix.com, par-AA7BB9E49434DE3B1B9D9A3373B69DE, RESELLER
					<br />
					pubmatic.com, 159172, RESELLER, 5d62403b186f2ace
					<br />
					facebook.com, 3631399360252851, DIRECT, c3e20eee3f780d68
					<br />
					admixer.co.kr, 1164, RESELLER
					<br />
					smartyads.com, 1445, DIRECT, fd2bde0ff2e62c5d
					<br />
					adform.com, 2795, RESELLER
					<br />
					loopme.com, 11342, RESELLER, 6c8d5f95897a5a3b
					<br />
					opera.com, pub5296098761088, RESELLER, 55a0c5fd61378de3
					<br />
					conversantmedia.com, 100264, RESELLER, 03113cd04947736d
					<br />
					xandr.com, 13167, DIRECT
					<br />
					pokkt.com, 5793, RESELLER, c45702d9311e25fd
					<br />
					admixer.co.kr, 1538, RESELLER
					<br />
					yahoo.com, 57872, DIRECT
					<br />
					indexexchange.com, 195491, RESELLER, 50b1c356f2c5c8fc
					<br />
					openx.com, 538959099, RESELLER, 6a698e2ec38604c6
					<br />
					amxrtb.com, 105199579, DIRECT
					<br />
					video.unrulymedia.com, 3563866356, RESELLER
					<br />
					opera.com, pub5184691729984, RESELLER, 55a0c5fd61378de3
					<br />
					betweendigital.com, 43837, RESELLER
					<br />
					sabio.us, 100098, RESELLER, 96ed93aaa9795702
					<br />
					applovin.com, d6ad9f13f399e612304a8a3b336573d1, DIRECT
					<br />
					adcolony.com, 5d8cbf6671c93a42, RESELLER, 1ad675c9de6b5176
					<br />
					rubiconproject.com, 16356, RESELLER, 0bfd66d529a55807
					<br />
					openx.com, 540785403, RESELLER, 6a698e2ec38604c6
					<br />
					pubmatic.com, 159509, RESELLER, 5d62403b186f2ace
					<br />
					pubnative.net, 1007170, RESELLER, d641df8625486a7b
					<br />
					verve.com, 15290, RESELLER, 0c8f5958fc2d6270
					<br />
					appnexus.com, 10617, RESELLER
					<br />
					appnexus.com, 9393, RESELLER
					<br />
					mintegral.com, 10038, RESELLER
					<br />
					openx.com, 541031350, RESELLER, 6a698e2ec38604c6
					<br />
					bidmachine.io, 114, RESELLER
					<br />
					bidmachine.io, 67, RESELLER
					<br />
					loopme.com, 11414, RESELLER, 6c8d5f95897a5a3b
					<br />
					google.com, pub-2059051664618607, RESELLER
					<br />
					pubmatic.com, 160846, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 161372, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 160113, RESELLER, 5d62403b186f2ace
					<br />
					criteo.com, B-057955, RESELLER, 9fac4a4a87c2a44f
					<br />
					liftoff.io, 7f6945815e6, RESELLER
					<br />
					yeahmobi.com, 5135195, RESELLER
					<br />
					exchange.admazing.co, AM3, RESELLER
					<br />
					indexexchange.com, 186684, RESELLER, 50b1c356f2c5c8fc
					<br />
					inmobi.com, 062029933580429f9920bad476d8d70a, RESELLER, 83e75a7ae333ca9d
					<br />
					yandex.com, 40427798, RESELLER
					<br />
					lemmatechnologies.com, 362, RESELLER, 7829010c5bebd1fb
					<br />
					meitu.com, 282, RESELLER
					<br />
					algorix.co, 60275, DIRECT, 5b394c12fea27a1d
					<br />
					aralego.com,par-D2346AAB7ABD36B4CDD7BBD264BA92E2,RESELLER
					<br />
					pubnative.net, 1007262, RESELLER, d641df8625486a7b
					<br />
					openx.com, 540838151, RESELLER, 6a698e2ec38604c6
					<br />
					smartadserver.com, 3817, RESELLER
					<br />
					tpmn.io, 415, RESELLER
					<br />
					vungle.com, 5d5a4104cec59b0017a0151f, DIRECT, c107d686becd2d77
					<br />
					adtiming.com, a-126, RESELLER, bf66753b8f380142
					<br />
					adview.com,32076181,RESELLER,1b2cc038a11ea319
					<br />
					algorix.co, 54250, RESELLER
					<br />
					betweendigital.com,43092,RESELLER
					<br />
					betweendigital.com,43843,RESELLER
					<br />
					chartboost.com, 5eac93f341016b09ff0019b6, RESELLER
					<br />
					criteo.com, 186227, RESELLER
					<br />
					flat-ads.com, 6, RESELLER
					<br />
					google.com,pub-3769010358500643,RESELLER,f08c47fec0942fa0
					<br />
					google.com,pub-4568609371004228,RESELLER,f08c47fec0942fa0
					<br />
					google.com, pub-7214269347534569, RESELLER, f08c47fec0942fa0
					<br />
					google.com, pub-9685734445476814, RESELLER, f08c47fec0942fa0
					<br />
					indexexchange.com,184738,RESELLER,50b1c356f2c5c8fc
					<br />
					indexexchange.com, 185578, RESELLER, 50b1c356f2c5c8fc
					<br />
					indexexchange.com, 185774, RESELLER, 50b1c356f2c5c8fc
					<br />
					indexexchange.com,194730,RESELLER,50b1c356f2c5c8fc
					<br />
					inmobi.com, 22e5354e453f49348325184e25464adb, RESELLER, 83e75a7ae333ca9d
					<br />
					inmobi.com, 5baa7ca93ef847c0876297e737dac3ee, RESELLER, 83e75a7ae333ca9d
					<br />
					inmobi.com,61d733c3779d43e590c51c8bc078e10c,RESELLER,83e75a7ae333ca9d
					<br />
					inmobi.com,867c89bb53994aaeb9dae3ce75b03e78,RESELLER,83e75a7ae333ca9d
					<br />
					inmobi.com,ab915bcef5b24940bf745f1a8f427bec,RESELLER,83e75a7ae333ca9d
					<br />
					inmobi.com, c1e6d3502da64ebaa3ad0e4a4be15f11, RESELLER, 83e75a7ae333ca9d
					<br />
					inmobi.com,ec6f6ceb8bb1440ba5455644ec96c275,RESELLER,83e75a7ae333ca9d
					<br />
					loopme.com, s-2411, RESELLER, 6c8d5f95897a5a3b
					<br />
					mars.media, 1010422, RESELLER, 8624339f102fb076
					<br />
					meitu.com, 149, RESELLER
					<br />
					meitu.com, 179, RESELLER
					<br />
					meitu.com, 253, RESELLER
					<br />
					openx.com,537149888,RESELLER,6a698e2ec38604c6
					<br />
					openx.com, 539315083, RESELLER, 6a698e2ec38604c6
					<br />
					openx.com, 540011801, RESELLER, 6a698e2ec38604c6
					<br />
					openx.com,540031703,RESELLER,6a698e2ec38604c6
					<br />
					openx.com, 540280728, RESELLER, 6a698e2ec38604c6
					<br />
					openx.com,540326226,RESELLER,6a698e2ec38604c6
					<br />
					openx.com, 540543195, RESELLER, 6a698e2ec38604c6
					<br />
					openx.com, 540679900, RESELLER, 6a698e2ec38604c6
					<br />
					openx.com,541031350,RESELLER,6a698e2ec38604c6
					<br />
					openx.com, 541177349, RESELLER
					<br />
					pubmatic.com, 8676470, RESELLER
					<br />
					pubmatic.com, 156517, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 156520, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com,156631,RESELLER,5d62403b186f2ace
					<br />
					pubmatic.com,156835,RESELLER,5d62403b186f2ace
					<br />
					pubmatic.com, 158060, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com,158100,RESELLER,5d62403b186f2ace
					<br />
					pubmatic.com,158154,RESELLER,5d62403b186f2ace
					<br />
					pubmatic.com, 158271, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 159542, RESELLER
					<br />
					pubmatic.com, 159668, RESELLER
					<br />
					pubmatic.com, 159831, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 159846, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 159897, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 160194, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 160195, RESELLER, 5d62403b186f2ace
					<br />
					rubiconproject.com,11726,RESELLER,0bfd66d529a55807
					<br />
					rubiconproject.com,12186,RESELLER,0bfd66d529a55807
					<br />
					rubiconproject.com,12266,RESELLER,0bfd66d529a55807
					<br />
					rubiconproject.com, 13626, RESELLER, 0bfd66d529a55807
					<br />
					rubiconproject.com,13856,RESELLER,0bfd66d529a55807
					<br />
					rubiconproject.com, 15278, RESELLER, 0bfd66d529a55807
					<br />
					rubiconproject.com,16114,RESELLER,0bfd66d529a55807
					<br />
					rubiconproject.com, 16834, RESELLER, 0bfd66d529a55807
					<br />
					rubiconproject.com,17328,RESELLER,0bfd66d529a55807
					<br />
					rubiconproject.com, 19724, RESELLER
					<br />
					rubiconproject.com,19724,RESELLER,0bfd66d529a55807
					<br />
					rubiconproject.com,20744,RESELLER,0bfd66d529a55807
					<br />
					rubiconproject.com,23644,RESELLER,0bfd66d529a55807
					<br />
					rubiconproject.com,23732,RESELLER,0bfd66d529a55807
					<br />
					smaato.com, 1100042823, RESELLER, 07bcf65f187117b4
					<br />
					smaato.com,1100044045,RESELLER,07bcf65f187117b4
					<br />
					smaato.com,1100049757,RESELLER,07bcf65f187117b4
					<br />
					smartadserver.com,1692,RESELLER
					<br />
					smartadserver.com,3797,RESELLER
					<br />
					smartadserver.com,4071,RESELLER
					<br />
					smartadserver.com,4111,RESELLER
					<br />
					smartyads.com, 368, RESELLER
					<br />
					spotx.tv, 283422, RESELLER, 7842df1d2fe2db34
					<br />
					spotxchange.com, 283422, RESELLER, 7842df1d2fe2db34
					<br />
					target.my.com, 8676470, RESELLER
					<br />
					tremorhub.com, s92od-u4sw5, RESELLER, 1a4e959a1b50034a
					<br />
					video.unrulymedia.com, 3704396951, RESELLER
					<br />
					video.unrulymedia.com,4268206200,RESELLER
					<br />
					webeyemob.com, 70090, RESELLER
					<br />
					webeyemob.com, 70101, RESELLER
					<br />
					rubiconproject.com, 23980, RESELLER, 0bfd66d529a55807
					<br />
					ignitemediatech.com, pub_61112, RESELLER
					<br />
					advlion.com,3117, RESELLER
					<br />
					yeahmobi.com, 15984, RESELLER
					<br />
					inmobi.com,5d2c913a0c5f4142acc7412723ee15d7,RESELLER,83e75a7ae333ca9d
					<br />
					adcolony.com,c7b2bb9f944ae42a,RESELLER,1ad675c9de6b5176 <br />
					appnexus.com,7351,RESELLER,f5ab79cb980f11d1
					<br />
					contextweb.com,562569,RESELLER,89ff185a4c4e857c
					<br />
					iqzone.com,IQ97,RESELLER
					<br />
					rhythmone.com,5336134699710583737,RESELLER,a670c89d4a324e47
					<br />
					acexchange.co.kr, 1885445389, RESELLER
					<br />
					pubmatic.com, 161368, RESELLER, 5d62403b186f2ace
					<br />
					mman.kr,30769,RESELLER
					<br />
					tpmn.io, 406, RESELLER
					<br />
					ucfunnel.com, par-AA7B236BA32DD484C838E249362437B8, RESELLER
					<br />
					aralego.com, par-AA7B236BA32DD484C838E249362437B8, RESELLER
					<br />
					adiiix.com, par-AA7B236BA32DD484C838E249362437B8, RESELLER
					<br />
					smartyads.com, 100102, RESELLER, fd2bde0ff2e62c5d <br />
					admixer.co.kr, 1252, RESELLER
					<br />
					tpmn.io, 218, RESELLER
					<br />
					bizzclick.com, 996, RESELLER, 7e936b1feafdaa61
					<br />
					rhythmone.com, 1295892552, RESELLER, a670c89d4a324e47 <br />
					video.unrulymedia.com, 1295892552, RESELLER
					<br />
					admixer.co.kr,1289,RESELLER <br />
					tpmn.io 391, RESELLER
					<br />
					appads.in, 107012, RESELLER
					<br />
					pokkt.com, 7012, RESELLER, c45702d9311e25fd
					<br />
					algorix.co, 60471, DIRECT, 5b394c12fea27a1d
					<br />
					ucfunnel.com,par-D2346AAB7ABD36B4CDD7BBD264BA92E2,RESELLER
					<br />
					ucfunnel.com,par-9A292878A83482E214D28D3933E8294B,RESELLER
					<br />
					aralego.com,par-9A292878A83482E214D28D3933E8294B,RESELLER
					<br />
					opera.com, pub4261820774272, RESELLER
					<br />
					themediagrid.com, FH3TKJ, Reseller, 35d5010d7789b49d
					<br />
					appnexus.com, 13297, RESELLER, f5ab79cb980f11d1
					<br />
					xandr.com, 13297, RESELLER, f5ab79cb980f11d1
					<br />
					pubmatic.com, 160974, RESELLER, 5d62403b186f2ace
					<br />
					rubiconproject.com, 20050, RESELLER, 0bfd66d529a55807
					<br />
					loopme.com, 11424, RESELLER, 6c8d5f95897a5a3b
					<br />
					ucfunnel.com,par-34BA8EB392678E3708238B4464BB3E4,RESELLER
					<br />
					aralego.com,par-34BA8EB392678E3708238B4464BB3E4,RESELLER
					<br />
					admixer.net, 5b8a86a9-7dd7-45c8-9daf-5d6ef00cbe46, RESELLER
					<br />
					yahoo.com, 59669, RESELLER, e1a5b5b6e3255540
					<br />
					loopme.com,11488,RESELLER,6c8d5f95897a5a3b
					<br />
					indexexchange.com, 197200, RESELLER, 50b1c356f2c5c8fc
					<br />
					admixer.co.kr, 1289, RESELLER
					<br />
					yeahmobi.com, 513536401, DIRECT
					<br />
					pubmatic.com, 161136, RESELLER, 5d62403b186f2ace
					<br />
					opera.com, pub5865193350528, RESELLER, 55a0c5fd61378de3
					<br />
					meitu.com, 699, RESELLER
					<br />
					webeyemob.com, 70104, RESELLER
					<br />
					bidence.com, ac6abd1022213c05377c1b26dbac5f36, DIRECT
					<br />
					algorix.co, 60465, DIRECT, 5b394c12fea27a1d
					<br />
					adpopcornssp.com, 736980247, DIRECT
					<br />
					algorix.co, 60471, DIRECT, 5b394c12fea27a1d
					<br />
					ucfunnel.com,par-D2346AAB7ABD36B4CDD7BBD264BA92E2,RESELLER
					<br />
					aralego.com,par-D2346AAB7ABD36B4CDD7BBD264BA92E2,RESELLER
					<br />
					ucfunnel.com,par-34BA8EB392678E3708238B4464BB3E4,RESELLER
					<br />
					aralego.com,par-34BA8EB392678E3708238B4464BB3E4,RESELLER
					<br />
					opera.com, pub4261820774272, RESELLER
					<br />
					smartadserver.com, 3817, RESELLER
					<br />
					pubnative.net, 1007262, RESELLER, d641df8625486a7b
					<br />
					openx.com, 540838151, RESELLER, 6a698e2ec38604c6
					<br />
					tpmn.io, 415, RESELLER
					<br />
					themediagrid.com, FH3TKJ, Reseller, 35d5010d7789b49d
					<br />
					appnexus.com, 13297, RESELLER, f5ab79cb980f11d1
					<br />
					xandr.com, 13297, RESELLER, f5ab79cb980f11d1
					<br />
					pubmatic.com, 160974, RESELLER, 5d62403b186f2ace
					<br />
					rubiconproject.com, 20050, RESELLER, 0bfd66d529a55807
					<br />
					inmobi.com, 22e5354e453f49348325184e25464adb, RESELLER, 83e75a7ae333ca9d
					<br />
					loopme.com, 11424, RESELLER, 6c8d5f95897a5a3b
					<br />
					appnexus.com, 11924, RESELLER, f5ab79cb980f11d1
					<br />
					appnexus.com, 13101, DIRECT, f5ab79cb980f11d1
					<br />
					appnexus.com, 13698, DIRECT, f5ab79cb980f11d1
					<br />
					appnexus.com, 13698, RESELLER, f5ab79cb980f11d1
					<br />
					appnexus.com, 3153, RESELLER, f5ab79cb980f11d1
					<br />
					appnexus.com,13227,RESELLER
					<br />
					digitalpiee.com,162588,DIRECT
					<br />
					digitalpiee.com,162588,RESELLER
					<br />
					digitalpiee.com,163354,DIRECT
					<br />
					digitalpiee.com,163354,RESELLER
					<br />
					ergadx.com,161334, DIRECT
					<br />
					ergadx.com,161335, DIRECT
					<br />
					ergadx.com,161336, DIRECT <br />
					milkywase.com, 159302, RESELLER
					<br />
					milkywase.com,159302, DIRECT
					<br />
					opera.com,pub7226695071424,DIRECT,55a0c5fd61378de3
					<br />
					pubmatic.com, 160881, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com,158565,RESELLER,5d62403b186f2ace
					<br />
					rubiconproject.com, 23102, RESELLER, 0bfd66d529a55807
					<br />
					rubiconproject.com, 23104, RESELLER, 0bfd66d529a55807
					<br />
					rubiconproject.com,23102,RESELLER,0bfd66d529a55807
					<br />
					smaato.com,1100042823,RESELLER,07bcf65f187117b4
					<br />
					video.unrulymedia.com,123476257,RESELLER
					<br />
					yahoo.com, 59639, RESELLER, e1a5b5b6e3255540
					<br />
					yahoo.com,57740,RESELLER,e1a5b5b6e3255540
					<br />
					yahoo.com,58935,RESELLER,e1a5b5b6e3255540
					<br />
					waardex.com, 83589, DIRECT
					<br />
					waardex.com, 288, DIRECT
					<br />
					opera.com,pub7612371571136,DIRECT,55a0c5fd61378de3
					<br />
					yahoo.com,58935,RESELLER,e1a5b5b6e3255540
					<br />
					admixer.co.kr,1610,RESELLER
					<br />
					tpmn.io, 532, RESELLER
					<br />
					smartadserver.com,4140,RESELLER
					<br />
					triplelift.com,11656,RESELLER,6c33edb13117fd86
					<br />
					appnexus.com,13227,RESELLER
					<br />
					pubmatic.com,162223,RESELLER,5d62403b186f2ace
					<br />
					inmobi.com,f58140d7af1a4fcb83deb5b1c76f0c04,RESELLER,83e75a7ae333ca9d
					<br />
					adyoulike.com,22389b7165228ff4ecbe2b72818ae524,RESELLER
					<br />
					video.unrulymedia.com,123476257,RESELLER
					<br />
					pubmatic.com,158565,RESELLER,5d62403b186f2ace
					<br />
					rubiconproject.com,17608,RESELLER,0bfd66d529a55807
					<br />
					loopme.com,11314,RESELLER,6c8d5f95897a5a3b
					<br />
					outbrain.com,002d7f7ba0bd74452f2b155d0dfb5cd6c8,RESELLER
					<br />
					smaato.com,1100042823,RESELLER,07bcf65f187117b4
					<br />
					admixer.co.kr, 1289, RESELLER
					<br />
					acexchange.co.kr, 1885445389, RESELLER
					<br />
					integralstream.com, 2, DIRECT
					<br />
					yeahmobi.com, 113717, DIRECT
					<br />
					rubiconproject.com, 24362, RESELLER, 0bfd66d529a55807
					<br />
					acexchange.co.kr, 1709715154, RESELLER
					<br />
					pubmatic.com, 162270, RESELLER, 5d62403b186f2ace
					<br />
					fyber.com, 211114, DIRECT <br />
					acd.op.hicloud.com, PUB_HW_1003, RESELLER
					<br />
					adcolony.com, 2851dffbb0d51b5e, RESELLER, 1ad675c9de6b5176
					<br />
					adcolony.com, 3dcf9f51638afe2c, RESELLER, 1ad675c9de6b5176
					<br />
					admanmedia.com, 26, RESELLER
					<br />
					advertising.com, 28650, RESELLER
					<br />
					adx-dra.op.hicloud.com, PUB_HW_1003, RESELLER
					<br />
					adx-dre.op.hicloud.com, PUB_HW_1003, RESELLER
					<br />
					adx-drru.op.hicloud.com, PUB_HW_1003, RESELLER
					<br />
					algorix.co, 54236, RESELLER, 5b394c12fea27a1d
					<br />
					algorix.co, 60082, RESELLER, 5b394c12fea27a1d
					<br />
					algorix.co, 60085, RESELLER, 5b394c12fea27a1d
					<br />
					algorix.co, 60086, RESELLER, 5b394c12fea27a1d
					<br />
					algorix.co, 60087, RESELLER, 5b394c12fea27a1d
					<br />
					algorix.co, 60093, RESELLER, 5b394c12fea27a1d
					<br />
					appnexus.com, 13258, RESELLER
					<br />
					appnexus.com, 14077, RESELLER
					<br />
					axonix.com, 56716, RESELLER
					<br />
					blis.com, 74, RESELLER, 61453ae19a4b73f4
					<br />
					contextweb.com, 561913, RESELLER, 89ff185a4c4e857c
					<br />
					contextweb.com, 562499, RESELLER, 89ff185a4c4e857c
					<br />
					conversantmedia.com, 100246, RESELLER, 03113cd04947736d
					<br />
					conversantmedia.com,100269,RESELLER,03113cd04947736d
					<br />
					indexexchange.com, 191572, RESELLER
					<br />
					indexexchange.com, 194730, RESELLER
					<br />
					inmobi.com, 1e13a47050bc40e29ef24c145820180d, RESELLER, 83e75a7ae333ca9d
					<br />
					inmobi.com, 7c9e112a810a4b639bb6af3fa397233e, RESELLER, 83e75a7ae333ca9d
					<br />
					inmobi.com, ebc44eef6c104f7fbf7bc52ee29f7939, RESELLER, 83e75a7ae333ca9d
					<br />
					loopme.com, 5176, RESELLER, 6c8d5f95897a5a3b
					<br />
					mars.media, 103116, RESELLER
					<br />
					openx.com, 537153564, RESELLER, 6a698e2ec38604c6
					<br />
					openx.com, 540274407, RESELLER, 6a698e2ec38604c6
					<br />
					openx.com, 540298543, RESELLER, 6a698e2ec38604c6
					<br />
					openx.com, 540866936, RESELLER, 6a698e2ec38604c6
					<br />
					openx.com,539315083,RESELLER,6a698e2ec38604c6
					<br />
					openx.com,545705254,RESELLER,6a698e2ec38604c6
					<br />
					opera.com,pub6739463379776,RESELLER,55a0c5fd61378de3
					<br />
					pubmatic.com, 156557, RESELLER
					<br />
					pubmatic.com, 157559, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 157899, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 158154, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 158291, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 158481, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 158723, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 160145, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 160145, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 160318, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 160319, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com,158271,RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com,162195,RESELLER,5d62403b186f2ace
					<br />
					pubnative.net, 1007055, RESELLER, d641df8625486a7b
					<br />
					rhebus.works, 3370572591, RESELLER
					<br />
					rhythmone.com, 4173858586, RESELLER, a670c89d4a324e47
					<br />
					rubiconproject.com, 13132, RESELLER, 0bfd66d529a55807
					<br />
					rubiconproject.com, 14558, RESELLER, 0bfd66d529a55807
					<br />
					rubiconproject.com, 15320, RESELLER, 0bfd66d529a55807
					<br />
					rubiconproject.com, 16928, RESELLER, 0bfd66d529a55807
					<br />
					rubiconproject.com, 17328, RESELLER, 0bfd66d529a55807
					<br />
					rubiconproject.com, 17608, RESELLER, 0bfd66d529a55807
					<br />
					rubiconproject.com, 18694, RESELLER, 0bfd66d529a55807
					<br />
					rubiconproject.com, 20744, RESELLER, 0bfd66d529a55807
					<br />
					rubiconproject.com, 22412, RESELLER, 0bfd66d529a55807
					<br />
					rubiconproject.com, 24204, RESELLER, 0bfd66d529a55807
					<br />
					rubiconproject.com,24448,RESELLER,0bfd66d529a55807
					<br />
					sharethrough.com, 6qlnf8SY, RESELLER, d53b998a7bd4ecd2
					<br />
					sharethrough.com, K6tB2uM1, RESELLER, d53b998a7bd4ecd2
					<br />
					smartclip.net,12358,RESELLER
					<br />
					smartclip.net,12422,RESELLER
					<br />
					smartstream.tv,667,RESELLER, 3193077
					<br />
					startapp.com, inr, RESELLER
					<br />
					thebrave.io, 1234585, RESELLER, c25b2154543746ac
					<br />
					thebrave.io, 1234598, RESELLER, c25b2154543746ac
					<br />
					triplelift.com, 11656, RESELLER, 6c33edb13117fd86
					<br />
					triplelift.com, 12456, RESELLER, 6c33edb13117fd86
					<br />
					triplelift.com, 8844, RESELLER, 6c33edb13117fd86
					<br />
					triplelift.com,10522,RESELLER,6c33edb13117fd86
					<br />
					ucfunnel.com, par-BE7E29B3B48DE66BC7DDDA24E6267E29, RESELLER
					<br />
					undertone.com, 4102, RESELLER
					<br />
					verve.com, 14592, RESELLER, 0c8f5958fc2d6270
					<br />
					video.unrulymedia.com,123476257,RESELLER
					<br />
					video.unrulymedia.com,7823643795310613975,RESELLER
					<br />
					VideoHeroes.tv, 212504, RESELLER, 064bc410192443d8
					<br />
					xad.com, 556, RESELLER, 81cbf0a75a5e0e9a
					<br />
					xandr.com, 13799, RESELLER
					<br />
					xandr.com,13709,RESELLER
					<br />
					yahoo.com, 59333, RESELLER, e1a5b5b6e3255540
					<br />
					yahoo.com, 59531, RESELLER, e1a5b5b6e3255540
					<br />
					yahoo.com, 59674, RESELLER, e1a5b5b6e3255540
					<br />
					yahoo.com, 59781, RESELLER, e1a5b5b6e3255540
					<br />
					yahoo.com,58935,RESELLER,e1a5b5b6e3255540
					<br />
					admanmedia.com, 2001, DIRECT
					<br />
					smartadserver.com, 3713, RESELLER
					<br />
					pubmatic.com, 158481, RESELLER, 5d62403b186f2ace
					<br />
					yahoo.com, 59674, RESELLER, e1a5b5b6e3255540
					<br />
					criteo.com, B-063105, RESELLER, 9fac4a4a87c2a44f
					<br />
					rhythmone.com, 3948367200, RESELLER, a670c89d4a324e47
					<br />
					contextweb.com, 561913, RESELLER, 89ff185a4c4e857c
					<br />
					rubiconproject.com, 14558, RESELLER, 0bfd66d529a55807
					<br />
					adform.com, 2671, RESELLER
					<br />
					openx.com, 540866936, RESELLER, 6a698e2ec38604c6
					<br />
					inmobi.com, 3a4f7da341dd490cbb7dde02b126275e, RESELLER, 83e75a7ae333ca9d
					<br />
					opera.com, pub5959307917824, RESELLER, 55a0c5fd61378de3
					<br />
					triplelift.com, 12456, RESELLER, 6c33edb13117fd86
					<br />
					opera.com, pub5959307917824, RESELLER, 55a0c5fd61378de3
					<br />
					themediagrid.com, A8X5S7, RESELLER, 35d5010d7789b49d
					<br />
					sonobi.com, 7b37f8ccbc, RESELLER, d1a215d9eb5aee9e
					<br />
					axonix.com, 57869, RESELLER
					<br />
					conversantmedia.com, 100308, RESELLER, 03113cd04947736d
					<br />
					freewheel.tv, 1235183, RESELLER
					<br />
					freewheel.tv, 1235279, RESELLER
					<br />
					zeta.com, 757, RESELLER
					<br />
					disqus.com, 757, RESELLER
					<br />
					vidoomy.com, 5630014, RESELLER
					<br />
					appnexus.com, 12700, RESELLER, f5ab79cb980f11d1
					<br />
					adform.com, 2671, RESELLER
					<br />
					pubmatic.com, 158481, RESELLER, 5d62403b186f2ace
					<br />
					admanmedia.com, 936, RESELLER
					<br />
					smartadserver.com, 3713, RESELLER
					<br />
					triplelift.com, 12456, RESELLER, 6c33edb13117fd86
					<br />
					pangleglobal.com, 70011, DIRECT
					<br />
					pubmatic.com, 161490, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 159172, RESELLER, 5d62403b186f2ace
					<br />
					roockmobile.com, 2343, DIRECT
					<br />
					conversantmedia.com, 100433, RESELLER, 03113cd04947736d
					<br />
					pubmatic.com, 160846, RESELLER, 5d62403b186f2ace
					<br />
					admixer.net, fb5d7b4a-e95e-44ac-8c66-b19240831746, DIRECT
					<br />
					yahoo.com,59669, RESELLER, e1a5b5b6e3255540
					<br />
					loopme.com,11488,RESELLER,6c8d5f95897a5a3b
					<br />
					rubiconproject.com,20744,RESELLER,0bfd66d529a55807
					<br />
					admixer.co.kr,1289,RESELLER <br />
					rubiconproject.com,14334,RESELLER,0bfd66d529a55807
					<br />
					admixer.net, fb5d7b4a-e95e-44ac-8c66-b19240831746, DIRECT
					<br />
					screencore.io, 37, DIRECT, a272bc30660e7ec7
					<br />
					smartyads.com, 100221, DIRECT, fd2bde0ff2e62c5d
					<br />
					aceex.io, 666, Reseller, b1cf3c874d5c6682
					<br />
					33across.com, 0015a00003EknbHAAR, Reseller, bbea06d9c4d2853
					<br />
					rubiconproject.com, 16414, RESELLER, 0bfd66d529a55807
					<br />
					yahoo.com, 57289, RESELLER, e1a5b5b6e3255540
					<br />
					appnexus.com, 10239, RESELLER, f5ab79cb980f11d1
					<br />
					pubmatic.com, 156423, RESELLER, 5d62403b186f2ace
					<br />
					openx.com, 537120563, RESELLER, 6a698e2ec38604c6
					<br />
					indexexchange.com, 191973, RESELLER, 50b1c356f2c5c8fc
					<br />
					conversantmedia.com, 100141, Reseller
					<br />
					rixserv.com, 604518, DIRECT <br />
					rixengine.com, 36069, reseller
					<br />
					bidence.com, ac6abd1022213c05377c1b26dbac5f36, RESELLER
					<br />
					rubiconproject.com, 25686, RESELLER, 0bfd66d529a55807
					<br />
					acexchange.co.kr, 1081983882, RESELLER
					<br />
					tpmn.io, 406, RESELLER
					<br />
					mman.kr, 32213, RESELLER
					<br />
					pubnative.net, 1007223, RESELLER, d641df8625486a7b
					<br />
					verve.com, 15503, RESELLER, 0c8f5958fc2d6270
					<br />
					pubmatic.com, 160145, RESELLER, 5d62403b186f2ace
					<br />
					pubmatic.com, 161144, RESELLER, 5d62403b186f2ace
					<br />
					rubiconproject.com, 17328, RESELLER, 0bfd66d529a55807
					<br />
					rubiconproject.com, 15278, RESELLER, 0bfd66d529a55807
					<br />
					gitberry.com, 577, RESELLER
					<br />
					videoheroes.tv, 212432, RESELLER, 064bc410192443d8
					<br />
					saharmedia.net, 1683397, RESELLER
					<br />
					blis.com, 137, RESELLER, 61453ae19a4b73f4
					<br />
					thebrave.io, 1234589, RESELLER, c25b2154543746ac
					<br />
					tpmn.io, 256, RESELLER
					<br />
					themediagrid.com, XYIOA3, RESELLER, 35d5010d7789b49d
					<br />
					video.unrulymedia.com, 4173858586, RESELLER
					<br />
					toponad.com, 161932b823ec20, DIRECT, 1d49fe424a1a456d
					<br />
					betweendigital.com, 45117, RESELLER
					<br />
					appnexus.com, 13817, RESELLER, f5ab79cb980f11d1
					<br />
					pubmatic.com, 162968, RESELLER, 5d62403b186f2ace
					<br />
					brightcom.com, 29559, RESELLER <br />
					vidoomy.com, 9148331, RESELLER
					<br />
					mangomob.net, axuppoxxi1, RESELLER
					<br />
					vidoomy.com, 9148331, RESELLER
					<br />
					pubnative.net, 1008379, RESELLER, d641df8625486a7b <br />
					google.com, pub-2099012165802613, DIRECT, f08c47fec0942fa0
				</Box>
			</Container>
			<Footer />
		</Fragment>
	);
};

export default FancastRootPage;
