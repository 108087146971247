import color from 'style/_color.scss';

const palette = () => {
	return {
		legacy: {
			blue: '#0094FD',
			darkNavy: '#292c47'
		},
		white: {
			1: color.white1
		},
		black: {
			1: color.black1,
			2: color.black2
		},
		grey: {
			1: color.grey1,
			2: color.grey2,
			3: color.grey3,
			4: color.grey4,
			5: color.grey5,
			6: color.grey6
		},
		navy: {
			1: color.navy1,
			2: color.navy2,
			3: color.navy3
		},
		purple: {
			1: color.purple1,
			2: color.purple2,
			3: color.purple3,
			4: color.purple4
		},
		yellow: {
			1: color.yellow1,
			2: color.yellow2
		}
	};
};

export default palette;
