import { ResponsiveModal } from 'components';

import { VOTE_POLICY_DETAIL } from 'views/sma/smaMonth/root/constants';
import { Grid, Typography, useTheme } from '@mui/material';

const SMAPolicyModal = ({ isOpenModal, setIsOpenModal }) => {
	const theme = useTheme();

	return (
		<ResponsiveModal
			title={VOTE_POLICY_DETAIL.title}
			open={isOpenModal}
			setOpen={setIsOpenModal}
		>
			<Grid container spacing={'24px'}>
				{VOTE_POLICY_DETAIL.content.map((policy, index) => (
					<Grid item container key={`vote-policy-content-${index}`}>
						<Grid item xs={12}>
							<Typography
								variant={'caption4'}
								color={theme.palette.grey[1]}
								fontWeight={500}
							>
								{policy.heading}
							</Typography>
						</Grid>
						{policy.list?.map((item, index) => (
							<Grid item container key={`vote-policy-content-list-${index}`}>
								<Grid item xs={'auto'}>
									<Typography
										variant={'caption4'}
										color={theme.palette.grey[1]}
										fontWeight={500}
									>
										∙
									</Typography>
								</Grid>
								<Grid item xs>
									<Typography
										variant={'caption4'}
										color={theme.palette.grey[1]}
										fontWeight={500}
									>
										{policy.list[index]}
									</Typography>
								</Grid>
							</Grid>
						))}
					</Grid>
				))}
			</Grid>
		</ResponsiveModal>
	);
};

export default SMAPolicyModal;
