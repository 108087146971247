import { Fragment } from 'react';

import {
	Box,
	Container,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import { Header, Footer } from 'views/fancast/legacy/components';

const FancastServicePage = () => {
	const theme = useTheme();
	const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

	return (
		<Fragment>
			<Header />
			<Container
				maxWidth={'lg'}
				className={'Fancast-Legacy'}
				sx={{ pt: 5, pb: 10 }}
			>
				<Box px={matchUpSm ? 0 : '20px'}>
					<Typography variant={'h11'} fontWeight={600} mb={2}>
						이용약관
					</Typography>
					<div>
						제1조(목적)
						<br />
						본 약관은 ㈜콘텐츠마당 (이하 "회사")이 제공하는 서비스(이하
						“서비스”)를 이용함에 있어 회원 및 회사 간의 제반 권리 의무와 관련
						절차 등을 규정하는데 그 목적이 있습니다.
						<br />
						<br />
						제2조(용어의 정의)
						<br />
						본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
						<br />
						"서비스"라 함은 “회사”가 개발 및 운영하는 모바일 어플리케이션
						플랫폼으로, “회원”이 이용하는 단말기(스마트폰, 태블릿 PC등의 장치
						포함)를 통해 “회사”가 제공하는 절차를 이행하면 그에 대한 보상과
						혜택을 받아, “회사”가 직, 간접으로 보유한 제휴 콘텐츠를 이용할 수
						있는 모바일 서비스를 의미합니다.
						<br />
						"회원"이라 함은 “서비스”를 이용하기 위해 “앱스토어 사업자” 또는
						“플랫폼 사업자”가 운영하는 앱스토어에서 애플리케이션을 다운로드
						받거나 웹 상에서 “회사”가 제공하는 “서비스” 일체를 이용하는 고객을
						의미합니다.
						<br />
						“앱스토어 사업자”란 “회사”가 제공하는 애플리케이션을 다운로드 받을
						수 있고, In-App결제를 할 수 있도록 하는 사업자를 말합니다.
						<br />
						“플랫폼 사업자”란 “회사”와 제휴하여 관련 서비스를 제공하는 웹,
						모바일 콘텐츠 사업자를 말합니다. <br />
						"광고주"라 함은 “회사”가 제공하는 광고 신청 절차에 따라 “회사”가
						제공하는 “서비스”에 광고 게재를 신청한 자를 의미합니다.
						<br />
						“유료서비스”란 “회사”가 유료로 제공하는 각종 모바일 등 온라인 디지털
						콘텐츠 및 제반 서비스를 의미합니다.
						<br />
						”하트”란 “회원”이 “서비스”를 이용함에 있어 광고시청, 충전소, 이벤트,
						프로모션 등 기타 서비스 이용 등을 통하여 적립하거나, 지급받는
						“디지털 아이템”을 말하며 재화로서의 가치는 없습니다.
						<br />
						“디지털 아이템”이란 디자인, 이미지, 프로그램, 솔루션, 문서, 책,
						음악, 애니메이션, 동영상, 영화, 게임, 방송 등 인간의 지식을 디지털
						파일 형태로 저장한 것을 “회사”가 “서비스”에 최적화되도록 2차 가공한
						것을 말한다.
						<br />
						"계정"이라 함은 “회원”의 식별과 “서비스” 이용을 위하여 “회원”이
						선정하고 “회사”가 승인하는 이메일 및 SNS와 연결된 아이디를 토대로
						만들어진 회원 정보를 의미합니다.
						<br />
						<br />
						제3조(약관의 명시, 효력 및 개정)
						<br />
						“회사”는 서비스 화면 및 서비스의 웹 페이지(https://fanca.io/, 이하
						"웹 페이지")에 게시하여, 회원에게 공지함으로써 효력이 발생합니다.
						<br />
						회사는 "약관의 규제에 관한 법률", "정보통신망 이용촉진 및 정보보호
						등에 관한 법률" 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할
						수 있습니다.
						<br />
						회사가 약관을 개정할 경우에는 기존약관과 개정약관 및 개정약관의
						적용일자와 개정사유를 명시하여 현행약관과 함께 그 적용일자 14일
						전부터 적용일자까지 공지합니다.
						<br />
						회사는 개정 약관의 공지를 규정된 방법 중 1가지 이상의 방법으로
						회원에게 명확하게 공지하도록 합니다.
						<br />
						<br />
						1. 웹 페이지 내 게시
						<br />
						2. E-mail 통보
						<br />
						3. 공식 SNS 채널 통보
						<br />
						4. 회사가 정한 기타 공지 방법 등<br />
						<br />
						본 약관의 개정과 관련하여 이의가 있는 “회원”은 회원탈퇴를 할 수
						있습니다. 단, 이의가 있음에도 불구하고 본 조 제1항 및 제2항에 따른
						회사의 공지가 있은 후 14일 이내에 회원탈퇴 및 거부의사를 표명하지
						않은 “회원”은 개정 약관에 동의한 것으로 봅니다.
						<br />
						<br />
						“회원”이 본 약관의 개정과 관련하여 이의가 있어 회원탈퇴를 진행하며
						환불 의사가 있을 경우 탈퇴 전 고객센터 및 전자우편을 통해 환불 의사
						및 제반 정보를 제공하여야 하며, 본 약관의 유료서비스 관련 환불정책을
						따릅니다.
						<br />
						<br />
						본 조의 규정에 의하여 개정 약관은 원칙적으로 그 효력 발생일로부터
						장래를 향하여 유효합니다.
						<br />
						<br />
						제4조(서비스 이용신청 및 이용계약 성립)
						<br />
						서비스 이용계약은 “회원”이 본 약관 및 개인정보처리방침 등에 대하여
						동의하고 “회사”가 제공하는 가입양식을 작성하여 서비스 이용을 신청한
						경우 “회사”가 이를 승낙함으로써 이용계약이 체결됩니다. 각 국가별
						규제에 따라 만14세 미만인 자는 “회원”으로 가입할 수 없습니다.
						<br />
						<br />
						“회원”은 제1항의 이용 신청 시 반드시 실제 정보(본인이 사용중인
						유효한 SNS 등)를 기재하여야 하며, 허위정보를 기재한 회원의 경우
						서비스 이용이 제한되거나 제5조에 의거 회원과의 서비스 이용계약을
						해지할 수 있습니다.
						<br />
						타인의 명의를 도용하는 등의 부정한 목적과 방법으로 이용 신청한
						회원의 ID는 사전 통지 없이 삭제될 수 있으며, 당해 회원은 서비스
						이용금지 및 법적 제재를 받을 수 있습니다.
						<br />
						제1항에 따른 신청에 있어 회사는 회원의 본인인증을 요청할 수
						있습니다.
						<br />
						“회사”는 회원들에게 원활한 서비스 제공을 위해 회원에게 이메일 등을
						통한 광고 및 서비스 관련 각종 정보를 제공할 수 있습니다.
						<br />
						“회사”는 기본적으로 “회원”의 별도 동의 없이 회원정보를 가입기간 동안
						제3자에게 제공하지 않습니다. 다만 법률에서 정한 특별한 규정이 있는
						경우와 서비스의 제휴사에게 제공하는 경우는 예외로 합니다.
						<br />
						“회사”는 서비스 운영 중 회사 정책에 따라 “광고주”가 광고송출을
						원하는 “회원”의 범주를 제한할 시 회원은 서비스 이용에 제한이 따를 수
						있습니다.
						<br />
						“회원”은 서비스를 이용 중 신청양식에 기재한 회원정보가 변경되었을
						경우 즉각 회원정보를 수정하여야 합니다. 회원정보 미 수정에 따라
						발생하는 모든 책임은 회원에게 있습니다.
						<br />
						<br />
						제5조(회원정보의 변경)
						<br />
						“회원”은 서비스 가입 시 지정한 단말기 식별번호, 아이디, 이메일, SNS
						ID 등의 수정이 불가능합니다.
						<br />
						“회원”은 본인의 개인정보를 허위로 설정하여 부당한 방법으로 이익을
						취할 경우, 당사자의 모든 “디지털 아이템”은 소멸되며 서비스 이용이
						제한될 수 있습니다.
						<br />
						<br />
						제6조(이용계약의 해지, 자격상실 및 정지)
						<br />
						“회원”은 언제든지 서비스의 이용을 원하지 않는 경우, 서비스 내 탈퇴를
						통하여 이용계약을 해지할 수 있습니다.
						<br />
						“회원”이 서비스 탈퇴를 신청하는 경우, 회원의 기 사용한 “디지털
						아이템”은 제외하고 모두 삭제 됩니다.
						<br />
						“회사”는 각 호에 해당하는 “회원”이 확인될 경우, 당해 “회원”에 대한
						사전 통보 없이 회원자격 유보/중지/해지 등의 조치를 취할 수 있으며,
						이 경우 “회원”은 서비스와 관련된 모든 권리를 주장할 수 없고, 이후
						회원 재가입이 금지됩니다.
						<br />
						<br />
						1. 회원가입 신청 시에 허위의 내용을 등록한 경우
						<br />
						2. “서비스”를 부정한 방법 또는 목적으로 이용한 경우
						<br />
						3. 회사가 서비스 내 공지사항을 통하여 공시하는 저작권 규정을
						위반하는 경우
						<br />
						4. 회원이 사망한 경우
						<br />
						5. 다른 회원의 서비스 홈페이지 이용을 방해하거나 그 정보를 도용하는
						등 전자거래질서를 위협하는 경우
						<br />
						6. 서비스 내 다른 회원 및 회사의 임직원에 대한 위협/희롱/지속적인
						고통 및 불편을 끼치는 경우
						<br />
						7. 서비스 내에서 회사의 운영자/임직원 또는 회사를 사칭한 경우
						<br />
						8. 회원 가입 신청 시 제3자의 개인정보를 이용 및 불법으로 도용한 경우
						<br />
						9. 서비스 이용 중 법률 위반행위 및 사회의 안녕/질서/미풍양속을
						해치는 행위를 한 경우
						<br />
						10. 제3자의 특허권, 상표권, 저작권, 영업비밀 등 지적재산권을 포함한
						기타 권리를 침해하는 행위를 한 경우
						<br />
						11. 회사로부터 특별한 권한을 부여 받지 않고 회사의 홈페이지 나
						클라이언트 프로그램을 변경 또는 회사의 서버를 해킹하는 등의 시스템을
						위협하는 행위를 한 경우
						<br />
						12. 회사의 사전승인 및 다른 회원에 대한 사전 동의 없이 Active X,
						스파이웨어, 애드웨어 등의 프로그램을 강제로 설치하게 한 경우
						<br />
						13. 서비스 방문자나 타 회원의 의사와 무관하게 특정 사이트로 방문을
						유도한 경우
						<br />
						14. 서비스 이용 중 불법복제 소프트웨어, 제3자의 저작물을 밀매하는
						등의 관련 법률에 저촉되는 행위를 한 경우
						<br />
						15. 인종/성/광신/이적 등의 반 사회적/도덕적 편견에 기반한 단체를
						결성하는 행위를 한 경우
						<br />
						16. 다른 회원의 ID/이메일 계정 등의 개인정보를 수집하는 행위
						<br />
						17. 범죄와의 결부, 관련 법령 위반 활동으로 판단되는 행위를 한 경우
						<br />
						18. 다른 회원으로 하여금 상기 6항 내지 17항의 금지행위를 하도록 유발
						및 조장하는 활동을 한 경우
						<br />
						19. 본 약관에 규정된 회원의 의무를 위반한 경우
						<br />
						20. 기타 당사가 공지한 서비스 운영 정책을 위반한 경우
						<br />
						<br />
						제7조(회원탈퇴와 “디지털 아이템” 처리)
						<br />
						본 약관 제6조 제2항에 정해진 방법으로 탈퇴를 하고자 하는 “회원”은
						회원탈퇴 요청 후 “회사”가 제공하는 절차를 이행하면 탈퇴를 하게
						됩니다. 이때 잔여 “디지털 아이템”은 자동으로 소멸되고 회원가입 시
						작성한 기본정보는 영구 소멸되며, 향후 3개월간 재가입이 금지됩니다.
						<br />
						본 약관 제6조 제3항에 정해진 바에 의하여 “회사”로부터 자격상실
						통보를 받은 “회원”의 기적립 “디지털 아이템”은 자격상실 통보와 동시에
						소멸되고, 이에 대하여 “회원”은 어떠한 권리도 주장할 수 없습니다.
						또한, 해당 회원은 이후 재가입이 금지됩니다.
						<br />
						<br />
						제8조(“서비스”의 제공)
						<br />
						“회사”가 본 약관에 정해진 바에 따라 “회원”에게 제공하는 “서비스”는
						다음 각 호와 같으며 “서비스”를 이용하고자 하는 고객은 본 약관에
						정해진 제반 절차를 거쳐 “회원”으로 가입하여야 합니다. 단, 본
						“서비스”의 타겟팅 서비스는 그 특성 상 “광고주”의 요구에 따라
						회원들은 일부 서비스의 이용조건, 이용범위 등이 제한되거나 회원에게
						일정한 자격요건이 요구될 수 있으며, 이 경우 회사는 그 제한사항 및
						자격요건을 회원께 고지해 드립니다.
						<br />
						<br />
						1. 적립 서비스
						<br />
						- 회원은 본 약관에 정해진 바에 따라 모바일을 통해 광고를 확인하고
						광고에서 요구하는 액션을 수행 및 제공되는 절차를 이행할 경우 “디지털
						아이템”을 적립받을 수 있습니다.
						<br />
						- “회원”은 “회사”가 요구하는 액션의 수행 절차를 이행할 경우 “디지털
						아이템”을 적립받을 수 있습니다.
						<br />
						2. 유료 서비스
						<br />
						- “회원”은 “회사” 및 제휴사가 제공하는 별도의 유료서비스에 대해서는
						해당 정보에 명시된 요금을 지불하여야 사용 가능합니다. <br />
						3. 제휴 콘텐츠 서비스
						<br />
						- “회원”은 본 약관에 정해진 바에 따라 기 적립된 “디지털 아이템”으로
						제휴 서비스를 이용할 수 있습니다.
						<br />
						4. 이벤트 서비스
						<br />
						- “회원”은 본 약관에 정해진 바에 따라 각종 이벤트에 참여할 수
						있습니다.
						<br />
						5. 기타
						<br />
						- “회사”는 상기 각 호의 “서비스” 이외에도 추가적인 서비스를 개발하여
						회원에게 제공할 수 있습니다.
						<br />
						<br />
						“회사”는 “서비스”의 원활한 제공을 위하여 고객센터를 운영하고 있으며,
						“서비스”에서 지원되지 못하는 보다 상세한 내용을 확인 및 문의할 수
						있습니다.
						<br />
						<br />
						제9조(“서비스”의 변경 및 중단)
						<br />
						“회사”는 “서비스”의 내용, 품질, 또는 기술적 사양 등에 대해 “회사”의
						합리적 판단에 따라 변경할 수 있습니다.
						<br />
						“회사”는 아래 각 호에 해당하는 사유가 발생한 경우에는 본 서비스의
						전부 또는 일부를 제한하거나 중단할 수 있습니다.
						<br />
						<br />
						1. “광고주”가 “회사”의 “서비스”를 고의·과실로 방해하는 경우
						<br />
						2. “서비스”용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우
						<br />
						3. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를
						중지했을 경우
						<br />
						4. 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등
						“서비스” 이용에 지장이 있는 때<br />
						5. 기타 중대한 사유로 인하여 “회사”가 “서비스” 제공을 지속하는 것이
						부적당하다고 인정하는 경우
						<br />
						<br />
						“회사”는 제1항 및 제3항의 각호 규정에 의하여 “서비스”를 변경하거나
						서비스 이용을 제한 및 중지 할 때에는 그 사유 및 제한기간 등을
						회원에게 발송하거나 서비스 내 공지사항에 게시하는 방법으로 알립니다.
						<br />
						<br />
						<br />
						제10조(“하트” 적립)
						<br />
						“회사”에서 제공하는 광고를 단말기를 통해 광고를 확인하고 광고에서
						요구하는 액션을 수행하면 제공되는 절차를 이행하면 “하트”를 부여 받게
						됩니다. “하트” 지급을 위하여 특정 회원의 자격요건이 요구될 수
						있습니다.
						<br />
						“회사” 내규 상에 적립 수량으로 명시된 수량만큼의 “하트”가 쌓입니다.
						<br />
						서버에 규정된 적립 수량과 클라이언트 앱 서비스 상 명시된 적립 수량이
						차이가 나는 경우 서버에 규정된 수량 금액이 우선 적용되며, 서버와
						클라이언트 앱과의 비동기화로 인한 “하트”의 차는 보상하지 않습니다.
						<br />
						“회사”는 “회사”가 지정한 제휴서비스에서 적립한 “하트”를 본
						서비스에서 이용 가능하도록 서버 연동을 허용할 수 있습니다.
						<br />
						<br />
						제11조(“디지털 아이템” 정정, 취소 및 소멸)
						<br />
						“디지털 아이템” 적립에 오류가 있을 경우 “회원”은 오류발생 시점부터
						90일 이내에 “회사”에 정정 신청을 하여야 하며, “회사”는 “회원”의 정정
						신청일로부터 90일 이내에 조정할 수 있습니다.
						<br />
						“회사”는 “회원”에게 부여한 “디지털 아이템”을 관리하고 운영하는
						역할을 담당합니다. 따라서 “회사”간의 거래과정에서 결제 문제 발생
						또는 파산, 부도 등 지급이 불가능한 경우에는 “회원”에게 기 부여된
						“디지털 아이템”이 취소될 수 있으며, 이에 대한 책임은 해당 사업자에게
						있습니다.
						<br />
						“디지털 아이템”의 적립 또는 사용이 없는 비활성화 상태가 12개월 이상
						계속된 “회원”의 “디지털아이템”은 자동 소멸됩니다.
						<br />
						<br />
						제12조 (유료서비스 이용)
						<br />
						“회사” 가 제공하는 별도의 유료서비스에 대해서는 해당 정보에 명시된
						요금을 지불해야 이용이가능합니다. <br />
						“회원”이 자동결제 상품을 구매하는 경우, 상품 구매 시 지정한
						결제수단으로 매월 또는 일정 시기에 자동으로 결제가 진행됩니다.
						<br />
						“회사”가 제공하는 유료서비스를 이용하고자 하는 “회원”은 유료
						서비스를 구매하기 전에 반드시 본 약관과 서비스에 기재한 유료
						서비스의 상세 내용과 거래의 조건을 정확하게 확인 후 약관에 동의하고
						구매를 하여야 합니다. 구매하려는 유료 서비스의 내용과 거래의 조건을
						확인하지 않고 구매하여 발생한 모든 손해에 대한 책임은 “회원”
						본인에게 있습니다.
						<br />
						“회원”은 어플리케이션 스토어 및 PG사 등 기타 결제방식을 통하여
						“회사”가 서비스하는 유료 서비스를 이용할 수 있습니다. “회사”와
						“회원”간 유료서비스 이용계약은 “회사”의 승낙이 “회원”에게 도달한
						시점(유료서비스의 “구매/결제완료 등”의 표시가 회원에게 절차상 표시된
						시점)에 성립합니다.
						<br />
						“회사”는 “디지털 아이템” 적립을 위한 유료 상품 및 유료 콘텐츠 구매
						등을 통한 유료서비스를 제공하며, 회사의 사정 및 기타 제반여건에 따라
						서비스 내용을 추가하거나 변경할 수 있습니다.
						<br />
						“회사”는 유료 서비스의 품질 또는 제공 관련 기술적 사양의 변경 등으로
						인하여 제공할 유료 서비스의 내용이 기존에 게재한 유료 서비스의
						내용과 다를 경우에는 차후 제공할 유료 서비스의 내용을 변경할 수
						있습니다. 이 경우 변경된 유료 서비스의 내용을 명시하여 공지합니다.{' '}
						<br />
						“회사”는 유료서비스를 이용하는 “회원”의 거래금액에 대하여 내부정책
						및 외부 결제업체 (오픈마켓, PG사, 카드사 등) 기타 관련 법령의 변경에
						따라 회원 당 월 누적 결제액, 결제한도 등의 거래한도를 정할 수
						있으며, “회원”은 “회사”가 정한 거래한도를 초과하는 범위의
						유료서비스를 이용하고자 할 경우 거래한도의 초과로 인하여
						유료서비스의 추가 이용이 불가능할 수 있습니다.
						<br />
						<br />
						제13조 (유료서비스의 청약 철회 및 환불)
						<br />
						“회사”의 “회원”에게 제공하는 유료서비스는 청약철회가 가능한
						청약철회와 제한되는 서비스로 구분되어 제공되며, 청약철회가 가능한
						서비스를 구입한 이용자는 구입 시부터 7일 이내에 청약을 철회할 수
						있습니다. 단, 아래 같은 경우에는 결제 취소 및 환불이 제한됩니다.
						<br />
						1.결제를 통하여 획득한 서비스의 이용권, 응모권, 쿠폰, 아이템,
						하트(보너스 포함) 등을 전부 또는 부분적으로 이미 사용한 경우
						<br />
						2.선물 및 이벤트 등의 방법으로 회사나 제3자로부터 유료서비스를
						제공받은 경우
						<br />
						3.결제를 통해 구매한 이용권이 즉시 사용이 시작되거나 서비스에 바로
						적용 되는 경우
						<br />
						4.회원의 단순 과실로 인하여 결제가 이루어진 경우
						<br />
						5.유료서비스에 대하여 전혀 사용하지 아니하였더라도, 청약 철회 가능한
						기간(결제일로부터 7일 이내)을 경과하여 청약 철회를 신청한 경우
						<br />
						그 외 관련법령에 따라 청약철회가 제한될 수 있으며, 이 경우 회사는
						관련 법령에서 정한 바에 따른 조치를 취합니다. 환불은 “회사” 또는 각
						“앱스토어 사업자”의 환불 운영정책에 따라 진행됩니다.
						<br />
						“회원”은 이미 결제한 유료 서비스 이용 계약을 해제, 해지 했을 때,
						계약 후 남은 계약 기간 동안 유료서비스를 이용할 수 있습니다.
						<br />
						“회원”은 고객센터 또는 전자우편을 통하여 청약철회 또는 환불을 요구할
						수 있으며, “회사”는 구매 대금의 환불을 위하여 구매 내역을 확인한 후
						추가적인 증빙을 요구할 수 있습니다.
						<br />
						청약철회 또는 환불의 원인이 “회사”의 서비스 폐지 등 회사의
						귀책사유로 인한 경우에는 구매 대급금의 환불에 따른 비용은 “회사”가
						부담합니다. 다만, 이러한 경우 이외에는 “회원”이 유료서비스 이용으로
						얻은 이익 및 환불에 따른 수수료를 공제하고 환불합니다.
						<br />
						미성년자가 법정 대리인의 동의 없이 결제한 경우, 본인 또는
						법정대리인은 결제를 취소할 수 있습니다. 단, 미성년자의 유료서비스
						결제가 법정 대리인으로부터 처분을 허락 받은 범위 내인 경우 또는
						미성년자가 속임수로써 자기를 성년자로 믿게 하거나 법정대리인의
						동의가 있는 것으로 믿게 한 경우에는 취소할 수 없습니다.
						<br />
						유료서비스의 구매 또는 동의에 따라 월 정기결제 중인 유료서비스의
						경우, 해당 회원이 유료서비스의 이용요금을 체납하는 경우 연체가
						발생한 날 자동으로 이용권 해지가 될 수 있으므로, 유료서비스를
						유지하고자 하는 유료회원은 이용요금의 체납 또는 결제수단의 연체가
						발생하지 않도록 사전에 조치하여야 합니다.
						<br />
						유료서비스의 이용 중 탈퇴하는 경우 해당 이용권은 즉시 해지되며
						환불은 불가능합니다. 이 경우 회원의 정보와 서비스 이용 내용은 약관에
						따라 처리됩니다.
						<br />
						청약철회 / 환불이 완료 되면 해당 구매 품목에 대한 회수가 진행 되며,
						이미 사용된 품목도 무효처리 되고, 품목을 제한 혹은 수정 할 수
						있습니다.
						<br />
						<br />
						제14조(“회사”의 의무)
						<br />
						“회사”는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지
						않으며, 계속적이고 안정적으로 “서비스”를 제공하기 위하여 최선을
						다하여 노력합니다.
						<br />
						“회사”는 “회원”이 안전하게 “서비스”를 이용할 수 있도록
						개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며
						개인정보처리방침을 공시하고 준수합니다.
						<br />
						“회사”는 서비스 이용과 관련하여 “회원”으로부터 제기된 의견이나
						불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. “회원”이
						제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 E-mail 등을
						통하여 “회원”에게 처리과정 및 결과를 전달합니다.
						<br />
						<br />
						제15조(“회원”의 의무)
						<br />
						“회원”은 관련법령, 이 약관의 규정, 이용안내 및 “회사”가 통지하는
						사항 등을 준수하여야 하며, 기타 “회사”의 업무에 방해되는 행위를
						하여서는 안됩니다. 적발 시 경우에 따라 경고, 일시정지, 영구이용정지
						등으로 서비스 이용을 단계적으로 제한하는 조치를 받을 수 있고, 관련
						법규에 의거하여 법적 조치가 이루어질 수 있습니다. 또한 “회원”은
						서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다. <br />
						<br />
						1. “서비스” 이용 관련 제반 신청행위 또는 변경행위 시 허위내용
						등록행위
						<br />
						2. “서비스” 내 게시된 각종 정보의 무단 변경, 삭제 등 훼손 행위
						<br />
						3. 다른 회원의 명예를 손상시키거나 개인정보를 수집하는 행위
						<br />
						4. “회사”의 동의 없이 영리 목적의 광고 정보를 전송하거나 회사가
						허용한 정보 이외의 다른 정보(컴퓨터 프로그램 및 광고 등)를
						송신하거나 게시하는 행위
						<br />
						5. 일체의 가공행위를 통해 서비스를 분해, 모방 또는 변형하는 행위
						<br />
						6. “회사”, 기타 제3자의 저작권 등 지적재산권에 대한 침해 행위
						<br />
						7. 외설 또는 폭력적인 정보(메시지, 화상, 음성 등), 기타 공서양속에
						반하는 정보를 서비스에 공개하거나 게시하는 행위
						<br />
						<br />
						제16조 (“회원”의 아이디 및 비밀번호의 관리에 대한 의무)
						<br />
						“회원”의 아이디와 비밀번호는 “회원”에게 관리책임이 있습니다.
						“회사”는 “회원”의 아이디가 개인정보 유출 우려가 있거나, 반사회적
						또는 미풍양속에 어긋나거나 “회사” 및 “회사”의 운영자로 오인할 우려가
						있는 경우, 해당 아이디의 이용을 제한할 수 있습니다. <br />
						“회원”은 아이디 및 비밀번호를 제3자가 이용하도록 제공하여서는 안
						됩니다. “회사”는 “회원”이 아이디 및 비밀번호를 소홀히 관리하여
						발생하는 서비스 이용상의 손해 또는 “회사”의 고의 또는 중대한 과실이
						없는 제3자의 부정이용 등으로 인한 손해에 대한 책임을 지지 않습니다.
						“회원”은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을
						인지한 경우에는 이를 즉시 “회사”에 통지하고 “회사”의 안내에 따라야
						합니다. “회사”는 위 사항을 만족하지 못했을 때 일어난 어떠한 손실이나
						피해에 대해 책임지지 않습니다. <br />
						<br />
						제17조 (외국 사용자를 위한 특별 규정)
						<br />
						“회사”는 모든 사람에게 적용되는 표준 서비스를 제공하는 동시에 외국
						사용자의 모국의 개인 법률 및 요구 사항을 준수하기 위해 노력하고
						있습니다. 이 조항은 한국 이외의 국가에서 서비스를 이용하는
						“회원”에게 적용됩니다.
						<br />
						“회원”은 한국에 정보 자료를 제출하고 저장하는 것에 동의해야 합니다.
						<br />
						한국 정부가 금지하는 국가에 거주하는 사용자는 특별 지정 국가 목록에
						따라 수출하거나 상업 서비스 (예 :광고 및 지불)에 참여하거나
						사용해서는 안됩니다.
						<br />
						<br />
						제18조 (“회원”의 게시물)
						<br />
						“회사”는 “회원”이 “서비스” 내에서 작성ㆍ게시하거나 등록한 문자,
						문서, 그림, 음성 또는 이들의 조합으로 이루어진 정보 등(이하
						'게시물')이 다음 각 호의 1에 해당한다고 판단되는 경우에 사전통지
						없이 일시적으로 제한하거나 삭제, 이동 또는 이용 제한 조치를 할 수
						있습니다.
						<br />
						<br />
						1. 타인을 비방하거나 명예를 손상시키는 내용인 경우
						<br />
						2. 공공질서 및 미풍양속에 위반되는 내용인 경우
						<br />
						3. 범죄적 행위에 결부된다고 인정되는 내용일 경우
						<br />
						4. “회사” 또는 타인의 저작권 기타 법률상 보호받는 권리를 침해하는
						내용인 경우
						<br />
						5. “회사”에서 규정한 게시기간을 초과한 경우
						<br />
						6. 관계 법령에 따라 청소년 유해 매체물로 지정예고 되거나 지정된 경우
						및 이에 준하는 게시물이라고 판단되거나 이에 준하는 링크를 게재하는
						경우
						<br />
						7. 타인의 개인정보를 도용하여 작성한 내용이거나, 타인이 입력한
						정보를 무단으로 위변조한 내용인 경우
						<br />
						8. 동일한 내용을 중복하여 다수 게시하는 등 게시의 목적에 어긋나는
						경우
						<br />
						9. “회사”에서 정한 게시물 작성 및 게시 원칙에 어긋나거나 게시판의
						성격에 부합하지 않는 게시물의 경우
						<br />
						10. “회사”의 서비스 운영을 방해하는 경우
						<br />
						11. 영리를 목적으로 하는 광고성 내용인 경우
						<br />
						12. “회사”가 관리 원칙으로 정한 수 이상의 “회원”이 해당 게시물을
						“서비스”가 제공하는 신고 기능을 사용하여 신고한 경우
						<br />
						13. “회사”가 관리 원칙으로 정한 수 이상의 “회원”이 “회원”의 게시물을
						열람하고 “회원”을 “서비스”가 제공하는 차단 기능을 사용하여 차단한
						경우
						<br />
						14. "정보통신망의 이용촉진 및 정보보호 등에 관한 법률", "저작권법",
						”개인정보보호법”, 기타 관계법령에 위반된다고 판단되는 경우
						<br />
						<br />
						“회원”은 각 호의 지침을 어기지 않는 범위에서 ‘게시물(회원간 전달
						포함)’을 게재하여야 합니다. “회원”은 각 호의 1에 해당되는 ‘게시물’에
						대해 “서비스”가 제공하는 신고 기능을 통해 ‘게시물’의 게시 중단 또는
						삭제 등을 요청할 수 있으며, “회사”는 관련 법령에 정해진 바에 따른
						조치를 취합니다.
						<br />
						<br />
						“회사”는 권리자의 요청 또는 요청이 없는 경우에도 권리침해가 인정될
						만한 사유가 있거나 기타 “회사” 정책 및 관련 법령에 위반되는 게시물에
						대해서는 관련 법령에 정한 바에 따라 해당 ‘게시물’에 대해 임시조치,
						삭제, 게시 거부, 해당 “회원”에 대한 이용 금지 조치 등을 취할 수
						있습니다. 세부절차는 해당 법령이 규정한 범위 내에서 “회사”가 정한
						관리 원칙을 따릅니다.
						<br />
						<br />
						제19조(저작권의 귀속 및 이용제한)
						<br />
						“회사”가 작성한 저작물에 대한 저작권 기타 지적재산권은 “회사”에
						귀속합니다.
						<br />
						“회원”이 작성한 저작물에 대한 저작권은 해당 게시물의 저작자에게
						귀속됩니다. 단, “회사”가 제공하는 “서비스”를 통해 직접 게시하는
						경우에는 “회사”의 서비스 및 관련 프로모션 등에 사전 동의없이 무료로
						사용할 수 있으며, 필요한 범위 내에서는 일부 수정, 복제, 편집되어
						게시될 수 있습니다.
						<br />
						“회원”은 “서비스”를 이용함으로써 얻은 정보를 “회사”의 사전 승낙 없이
						복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로
						이용하거나 제3자에게 이용하게 하여서는 안됩니다.
						<br />
						<br />
						제20조(“서비스” 관련 분쟁해결)
						<br />
						“회사”는 “서비스” 이용과 관련하여 “회원”으로부터 제출되는 불만사항
						및 의견을 최대한 신속하게 처리합니다. 다만, 신속한 처리가 곤란한
						경우에는 “회원”에게 그 사유와 처리일정을 조속히 통보해 드립니다.
						<br />
						“회사”와 “회원”간에 발생한 분쟁은 전자거래기본법에 의하여 설치된
						전자거래분쟁 조정위원회의 조정을 거칩니다.
						<br />
						<br />
						제21조(준거법 및 합의관할)
						<br />
						본 약관에서 정하지 않은 사항과 본 약관의 해석에 관하여는 대한민국 법
						및 상관례에 따릅니다.
						<br />
						서비스 및 본 약관과 관련한 제반 분쟁 및 소송은 서울중앙지방법원 또는
						민사소송법상의 관할법원을 제1심 관할법원으로 합니다.
						<br />
						<br />
						제22조(개인정보보호 의무)
						<br />
						“회사”는 관련법령이 정하는 바에 따라서 “회원” 등록정보를 포함한
						“회원”의 개인정보를 보호하기 위하여 노력합니다. “회원”의
						개인정보보호에 관해서는 관련법령 및 “회사”가 정하는
						개인정보처리방침에서 정한 바에 의하며, “회원”이 상시 확인할 수
						있도록 하고 있습니다.
						<br />
						<br />
						제23조 (휴면계정)
						<br />
						“회원”이 12개월동안 “서비스”를 이용하지 않았을 경우 원활한
						회원관리를 위하여 휴면계정으로 처리하며, 회원 활동과 “디지털
						아이템”은 사용을 제한할 수 있습니다.
						<br />
						“회사”는 “회원”이 휴면계정으로 처리된 후 일정기간이 지나면 본
						약관에서 정한 방법으로 통지 후 해당 “회원”의 “디지털 아이템”을
						소멸시킬 수 있습니다.
						<br />
						<br />본 약관은 2022년 10월 31일부터 적용됩니다.
					</div>
				</Box>
			</Container>
			<Footer />
		</Fragment>
	);
};

export default FancastServicePage;
