import { Fragment } from 'react';

import { Header, Main, Footer } from 'views/fancast/legacy/components';

const FancastRootPage = () => {
	return (
		<Fragment>
			<Header />
			<Main />
			<Footer />
		</Fragment>
	);
};

export default FancastRootPage;
