import {
	Box,
	Container,
	Grid,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import images from 'assets/images';

import { ResponsiveImage } from 'components';

const SMA_BELT = [
	{ text: '서울가요대상 이달의 아티스트' },
	{ text: 'Seoul Music Awards Artist of the Month' },
	{ text: '서울가요대상 이달의 아티스트' },
	{ text: 'Seoul Music Awards Artist of the Month' },
	{ text: '서울가요대상 이달의 아티스트' },
	{ text: 'Seoul Music Awards Artist of the Month' }
];

const SMA_BELT_MOBILE = [
	{ text: '서울가요대상 이달의 아티스트' },
	{ text: 'SMA Artist of the Month' },
	{ text: '서울가요대상 이달의 아티스트' },
	{ text: 'SMA Artist of the Month' }
];

const SmaBelt = styled(Box)(({ theme, matchUpSm }) => ({
	width: '100%',
	backgroundColor: theme.palette.black[1],
	...(!matchUpSm && {
		padding: '0 20px'
	})
}));

const StyledContainer = styled(Container)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center'
}));

const Banner = () => {
	const theme = useTheme();
	const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

	if (matchUpSm) {
		return (
			<div>
				<Container maxWidth={1200}>
					<Grid container>
						<ResponsiveImage
							src={images.SmaMainPc}
							alt={'sma_main_image'}
							wrapperProps={{
								pt: 'calc(595/1200*100%)'
							}}
						/>
					</Grid>
				</Container>
				<SmaBelt theme={theme} matchUpSm={matchUpSm}>
					<StyledContainer maxWidth={1200} sx={{ height: '50px', overflow: 'hidden' }}>
						{SMA_BELT.map((item, index) => (
							<Typography
								key={`sma-text-${index}`}
								variant={'caption5'}
								color={theme.palette.white[1]}
								whiteSpace={'nowrap'}
								mr={'54px'}
							>
								{item.text}
							</Typography>
						))}
					</StyledContainer>
				</SmaBelt>
			</div>
		);
	}

	return (
		<div>
			<Container maxWidth={1200}>
				<Grid container>
					<ResponsiveImage
						src={images.SmaMainMobile}
						alt={'sma_main_image'}
						wrapperProps={{
							pt: 'calc(483/375*100%)'
						}}
					/>
				</Grid>
			</Container>
			<SmaBelt theme={theme} matchUpSm={matchUpSm}>
				<StyledContainer maxWidth={1200} sx={{ height: '40px', overflow: 'hidden' }}>
					{SMA_BELT_MOBILE.map((item, index) => (
						<Typography
							key={`sma-text-${index}`}
							variant={'caption6'}
							color={theme.palette.white[1]}
							whiteSpace={'nowrap'}
							mr={'35px'}
						>
							{item.text}
						</Typography>
					))}
				</StyledContainer>
			</SmaBelt>
		</div>
	);
};

export default Banner;
