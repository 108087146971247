import { Box, Container, styled, useMediaQuery, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';

import images from 'assets/images';

const StyledLocationBox = styled(Box)(({ matchUpMd }) => ({
	position: 'absolute',
	top: matchUpMd ? 236 : 200,
	right: 0,
	zIndex: 100
}));

const SMARealTrophy = () => {
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
	const { pathname } = useLocation();

	if (matchUpMd) {
		return (
			<Container maxWidth={1200}>
				<Box sx={{ position: 'relative' }}>
					<StyledLocationBox matchUpMd={matchUpMd}>
						<img
							src={images.SmaRealTrophy}
							alt={'sma_real_trophy_image'}
							width={347}
							height={778}
						/>
					</StyledLocationBox>
				</Box>
			</Container>
		);
	}

	return (
		<Container maxWidth={1200}>
			<Box sx={{ position: 'relative' }}>
				<img
					src={images.SmaRealTrophy}
					alt={'sma_real_trophy_image'}
					width={185}
					height={441}
					style={{
						position: 'absolute',
						zIndex: 100,
						top: pathname === '/sma/month/about' ? 187 : '16rem',
						right: 0
						// right: '-20px',
						// clip: 'rect(0, 177px, 461px, 0)'
					}}
				/>
			</Box>
		</Container>
	);
};

export default SMARealTrophy;
