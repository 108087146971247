import { useEffect } from 'react';

import { instance } from 'api/setupAxios';

export const useAxiosInterceptors = () => {
	useEffect(() => {
		// requestInterceptor
		const requestInterceptor = instance.interceptors.request.use(
			(config) => {
				return config;
			},
			(error) => Promise.reject(error)
		);

		// responseInterceptor
		const responseInterceptor = instance.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		return () => {
			instance.interceptors.request.eject(requestInterceptor);
			instance.interceptors.response.eject(responseInterceptor);
		};
	}, []);
};
