import {
	ButtonBase,
	Container,
	Grid,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import images from 'assets/images';

import { APP_FANCAST_VOTE_MAIN_URL } from 'constants/common';

const StyledDirectVoteButton = styled(ButtonBase)(({ theme, matchUpMd }) => ({
	width: '100%',
	height: matchUpMd ? 100 : 80,
	backgroundColor: theme.palette.black[1]
}));

const SMA06Component = () => {
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<StyledDirectVoteButton
			component={'button'}
			disableRipple
			matchUpMd={matchUpMd}
			onClick={() => window.open(APP_FANCAST_VOTE_MAIN_URL)}
		>
			<Container maxWidth={1200}>
				<Grid
					container
					alignItems={'center'}
					justifyContent={'center'}
					rowSpacing={'4px'}
				>
					<Grid item xs={12} md={'auto'}>
						<Typography
							variant={matchUpMd ? 'caption1' : 'caption2'}
							color={theme.palette.white[1]}
						>
							이달의 아티스트
						</Typography>
						<Typography
							variant={matchUpMd ? 'caption1' : 'caption2'}
							color={theme.palette.white[1]}
							fontWeight={700}
							ml={'6px'}
						>
							지금 투표하기
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={'auto'}
						display={'flex'}
						flexDirection={'row'}
						alignItems={'center'}
						justifyContent={'center'}
					>
						<Typography
							variant={matchUpMd ? 'caption3' : 'caption5'}
							color={theme.palette.yellow[1]}
							ml={matchUpMd ? '20px' : 0}
							mr={'8px'}
						>
							팬캐스트 바로가기
						</Typography>
						<img
							src={images.SmaRightDirectionYellowSm}
							alt={'ic_right_direction_yellow'}
							width={matchUpMd ? 18 : 15}
							height={matchUpMd ? 6 : 5}
						/>
					</Grid>
				</Grid>
			</Container>
		</StyledDirectVoteButton>
	);
};

export default SMA06Component;
